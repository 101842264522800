
import { Component, Vue, Watch } from 'vue-property-decorator';
import CampaignWizardTitle from '@/ui-components/CampaignWizardTitle/CampaignWizardTitle.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import ChangeMedia from '@/shared-components/ChangeMedia.vue';
import { verticalScreenSpecs } from '@/statics/screens';
import {
  ChangeCampaignMedia,
  GetCampaignById,
  ResetAllCampaigns,
} from '@/store/modules/advertiser-module/types';
import namespaces from '@/store/namespaces';
import { AdvertiserCampaign } from '@/types/campaigns';
import { Action } from 'vuex-class';
import { MediaFile } from '@/types/media';
import { ShowSnackbar } from '@/types/snackbar';
import { SYSTEM_STATUS } from '@/statics/system-status';
import { getAbbreviatedUserType, updateCampaignDesignerEmail, updateCampaignMediaList } from '@/actions/campaigns/actions';
import {
  mapMediaToLocations, mapMediaToLocationsWithMetadata,
  mapCampaignMediaToMediaFile,
  mapMediaResolutionsToCampaignSelectedLocations
} from '@/utils/media';
import MediaUploadGuidelines from '@/shared-components/MediaUploadGuidelines.vue';
import { LocationData } from '@/types/locations';
import NeedHelp from '@/pages/shared/CampaignWizard/components/AdContent/components/NeedHelp.vue';
import UploadLater from '@/pages/advertiser/ChangeMedia/components/UploadLater.vue';
import UploadNow from '@/pages/advertiser/ChangeMedia/components/UploadNow.vue';
import { emailRegex } from '@/validation-rules';
import { UpdateDesignerEmail } from '@/store/modules/campaign-wizard/types';
import { cloneDeep } from 'lodash';
import { updateCampaignPropertiesByStatusWhenMediaChange } from '@/utils/campaigns';
import { getUserInfoById } from '@/actions/users/actions';

@Component({
  name: 'ChangeMediaPage',
  components: {
    CampaignWizardTitle,
    MediaUploadGuidelines,
    ChangeMedia,
    IButton,
    NeedHelp,
    UploadLater,
    UploadNow,
  },
})
export default class ChangeMediaPage extends Vue {
  @Action('getCampaignById', { namespace: namespaces.AdvertiserModule })
  public getCampaignById!: GetCampaignById;

  @Action('changeCampaignMedia', {
    namespace: namespaces.AdvertiserModule,
  })
  public changeCampaignMedia!: ChangeCampaignMedia;

  @Action('resetAllCampaigns', {
    namespace: namespaces.AdvertiserModule,
  })
  public resetAllCampaigns!: ResetAllCampaigns;

  @Action("updateDesignerEmail", { namespace: namespaces.CampaignWizardModule })
  public updateDesignerEmail!: UpdateDesignerEmail;

  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  public campaign: AdvertiserCampaign | null = null;
  public newMediaList: MediaFile[] | null = null;
  public oldMediaList: MediaFile[] | null = null;
  public loading = false;
  public uploadNow = 'UPLOAD_NOW';
  public uploadLater = 'UPLOAD_LATER';
  public selectedMediaOption = this.uploadNow;
  public designerEmailValue = '';

  public get campaignId() {
    return this.$route.params.id;
  }

  public get isCampaignRunning() {
    return this.campaign?.status.VAL === SYSTEM_STATUS.RUNNING.VAL;
  }

  public get screenSpecs() {
    return verticalScreenSpecs;
  }

  public get hasRequest() {
    return false;
  }

  public async getCampaignData() {
    this.campaign = await this.getCampaignById(this.campaignId);
    if (this.campaign) {
      const {
        dbDataObject: {
          MEDIA_SKIPPED: mediaSkipped,
          DESIGNER_EMAIL: designerEmail
        }
      } = this.campaign

      this.newMediaList = mapMediaResolutionsToCampaignSelectedLocations(this.campaign.locations, this.campaign.mediaList);
      const clonedMediaList = cloneDeep(this.newMediaList);
      this.oldMediaList = mapMediaToLocations({
        mediaItems: clonedMediaList,
        locations: this.campaign.locations
      })
        .map((campaignMedia) => mapCampaignMediaToMediaFile(campaignMedia))
      if (mediaSkipped) {
        this.selectedMediaOption = this.uploadLater;
      }
      this.designerEmailValue = designerEmail || ''
      this.updateDesignerEmail(this.designerEmailValue)
    }
    if (!this.campaign || !this.campaignId) {
      this.back();
    }
  }

  get canSubmit() {
    if (this.selectedMediaOption === this.uploadNow) {
      const oldMediaList = mapMediaResolutionsToCampaignSelectedLocations(this.campaign?.locations as LocationData[] || [], this.campaign?.mediaList || []);
      const isAllFilesResolutionsUploaded = () => {
        return this.newMediaList?.every(media => media.path)
      }
      const isAtLeastOneMediaFileChanged = () => {
        return this.newMediaList?.some((media, index) => oldMediaList[index].path !== media.path)
      }
      return isAllFilesResolutionsUploaded() && isAtLeastOneMediaFileChanged();
    } else {
      return this.designerEmailValue && emailRegex.test(this.designerEmailValue)
    }
  }

  public handleOptionSelection(value: string) {
    if (value === this.uploadNow) {
      if (this.campaign?.mediaList?.length === 0) {
        this.newMediaList = mapMediaResolutionsToCampaignSelectedLocations(this.campaign?.locations as LocationData[], [])
      } else {
        this.newMediaList = mapMediaResolutionsToCampaignSelectedLocations(
          this.campaign?.locations as LocationData[], this.campaign?.mediaList as MediaFile[]
        );
      }
    } else {
      this.newMediaList = [];
    }
  }

  public async submit() {
    try {
      const {
        campaignRequest,
        status,
        locations,
        name: campaignName,
      } = this.campaign!;
      this.loading = true;
      if (this.selectedMediaOption === this.uploadLater) {
        await updateCampaignDesignerEmail(campaignRequest.ADVERTISER_UID, campaignRequest.ID, this.designerEmailValue)
      } else {
        const { ROLES, COMPANY_NAME: companyName } = await getUserInfoById(campaignRequest.ADVERTISER_UID)
        const abbreviatedUserType = getAbbreviatedUserType(ROLES || [])
        if (status.VAL !== SYSTEM_STATUS.RUNNING.VAL) {
          const mediaList = mapMediaToLocationsWithMetadata({
            mediaItems: this.newMediaList as MediaFile[],
            locations: locations,
            abbreviatedUserType,
            campaignName,
            companyName,
          });
          const updatedCampaignPropertiesByStatus = updateCampaignPropertiesByStatusWhenMediaChange(status);
          await updateCampaignMediaList({
            userId: campaignRequest.ADVERTISER_UID,
            campaignId: campaignRequest.ID,
            invalidateDesignerAccessCode: false,
            mediaList,
            updatedCampaignPropertiesByStatusWhenMediaChanged: updatedCampaignPropertiesByStatus
          });
        } else {
          const newMediaList = mapMediaToLocationsWithMetadata({
            mediaItems: this.newMediaList as MediaFile[],
            locations: locations,
            abbreviatedUserType,
            campaignName,
            companyName,
          }).map((campaignMedia) => mapCampaignMediaToMediaFile(campaignMedia));

          this.changeCampaignMedia({
            campaignRequest,
            status,
            newMediaList,
            oldMediaList: this.oldMediaList as MediaFile[],
          });
        }
      }
      this.loading = false;
      let message;
      if (this.selectedMediaOption === this.uploadLater) {
        message = 'email_sent_to_designer_successfully';
      } else {
        message = this.isCampaignRunning
          ? 'media_change_request_sent_successfully'
          : 'media_changed_successfully';
      }

      const text = this.$t(message) as string;
      this.showSnackbar({
        color: 'success',
        text,
      });
      this.resetAllCampaigns();
      this.back();
    } catch (error) {
      const message = 'problem_changing_campaign_media';
      const text = this.$t(message) as string;
      this.showSnackbar({
        color: 'danger',
        text,
      });
    }
  }

  public back() {
    this.$router.go(-1);
  }

  public created() {
    this.getCampaignData();
  }
}
