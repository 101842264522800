
import { Component, Prop, Vue } from 'vue-property-decorator';
import CampaignsTable from '@/shared-components/CampaignsTable/CampaignsTable.vue';
import { UpcomingCampaign, StoreCampaign } from '@/types/campaigns';
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { SetStoreCampaigns } from '@/store/modules/store-module/types';
import { regulatorRouteNames } from '@/route-names/regulator';

@Component({
  name: 'UpcomingCampaignsTable',
  components: {
    CampaignsTable,
  },
})
export default class UpcomingCampaignsTable extends Vue {
  @Prop({ type: Array, default: () => [] })
  public campaigns!: UpcomingCampaign[];

  @Prop({ type: String })
  public activeTab!: string;

  @Action('setStoreCampaigns', {
    namespace: namespaces.StoreModule,
  })
  public setStoreCampaigns!: SetStoreCampaigns;

  public itemsPerPage = 10;

  public get headers (){
    return [
    {
      text: this.$t('advertiser'),
      value: 'advertiserCompanyName',
    },
    {
      text: this.$t('start_date'),
      value: 'startDate',
    },
    {
      text: this.$t('end_date'),
      value: 'endDate',
    },
    {
      text: this.$t('_status'),
      value: 'status',
    },
    {
      text: this.$t('branches'),
      value: 'branchesNames',
    },
    {
      text: this.$t('total_duration'),
      value: 'durationInWeeks',
    },
    {
      text: this.$t('total_expected_revenue'),
      value: 'expectedTotalEarning',
    },
  ];
   }

  public openCampaignViewPage({ campaignRequest: { ID: id } }: StoreCampaign) {
    this.setStoreCampaigns(this.campaigns);
    this.$router.push({
      ...regulatorRouteNames.MY_CAMPAIGN_DETAILS,
      params: {
        id
      }
    })
  }
}
