
import { Component, Prop, Vue } from 'vue-property-decorator';
import BigCard from '@/ui-components/BigCard/BigCard.vue';
import CampaignStatus from '@/ui-components/CampaignStatus/CampaignStatus.vue';
import { CompactCampaign } from '@/types/campaigns';

@Component({
  name: 'CompactCampaignsTable',
  components: {
    BigCard,
    CampaignStatus
  },
})
export default class CompactCampaignsTable extends Vue {
  @Prop({ type: Array, default: () => [] })
  public campaigns!: CompactCampaign[];

  public get headers (){
    return [
    {
      name: this.$t('name'),
      value: 'name',
    },
    {
      name: this.$t('start_date'),
      value: 'startDate',
    },
    {
      name: this.$t('end_date'),
      value: 'endDate',
    },
    {
      name: this.$t('_status'),
      value: 'status',
    },
    {
      name: this.$t('advertiser'),
      value: 'advertiser',
    },
    {
      name: this.$t('duration'),
      value: 'duration',
    },
  ];
  }
}
