
import { Component, Prop, Vue } from 'vue-property-decorator';
import IDialog from '@/ui-components/IDialog/IDialog.vue';
import IButton from '@/ui-components/IButton/IButton.vue';

@Component({
    name: 'CheckDurationDialog',
    components: {
        IDialog,
        IButton,
    },
})
export default class CheckDurationDialog extends Vue {
    isOpen = false;
    openDialog() {
        this.isOpen = true;
    }
    closeDialog() {
        this.isOpen = false;
    }
}
