import firebase from 'firebase';
import moment from 'moment';
import { SerializedFirebaseTimestamp } from '@/types/date';
import i18n from '@/plugins/i18n';

export const formatDate = (value: Date = new Date()) => {
  return moment(value).format('DD-MM-YYYY');
};
export const getMomentLocaleKey = () => {
  const locale = localStorage.getItem('language');
  return locale === 'ar' ? 'ar-ly' : 'en'
}
export const formatDateWith = (value: Date = new Date(), format: string) => {
  return moment(value).locale(getMomentLocaleKey()).format(format);
};

export const formatDateTime = (value: Date = new Date()) => {
  return moment(value).locale(getMomentLocaleKey()).format(`dddd, 	MMMM D, YYYY [${ i18n.t('_at') }] HH:mm`);
};

export const monthYear = (value: Date = new Date()) => {
  return moment(value).format('MMM YYYY');
};

export const year = (value: Date = new Date()) => {
  return moment(value).format('YYYY');
};

export const getDateFromServerTimeStamp = (value?: firebase.firestore.Timestamp | string): Date => {
  if (!value) {
    return new Date();
  }

  if (value instanceof firebase.firestore.Timestamp) {
    return value.toDate();
  }
  if ((value as unknown as SerializedFirebaseTimestamp)._seconds) {
    return new Date((value as unknown as SerializedFirebaseTimestamp)._seconds * 1000);
  }

  return new Date(value);
};

export const dateMinimizer = (date: string) => {
  return date
    .replaceAll('years', 'yrs')
    .replaceAll('months', 'mon')
    .replaceAll('hours', 'hrs')
    .replaceAll('minutes', 'mins')
    .replaceAll('seconds', 'secs');
}
