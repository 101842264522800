
import { NavButtonData } from '@/types/nav-buttons';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'NavButton',
})
export default class extends Vue {
  @Prop({ type: Object, default: () => ({}) }) button!: NavButtonData;

  public navigateToRoute() {
    if (this.button.route) {
      this.$router.push(this.button.route);
    }
    this.button?.action && this.button.action();
  }
}
