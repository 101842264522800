import { USER_ROLES } from '@/statics/user-roles';
import { createUserInfo, getUserInfoById } from '@/actions/users/actions';
import { FirebaseAppAuth } from '@/firebase/firebase-app';
import router from '@/plugins/router';
import store from '@/store';
import {
  AuthorizedUser,
  LoggedInUser,
  LoginUserData,
  UserRegistrationData,
} from '@/types/users';
import firebase from 'firebase';
import { publicRouteNames } from '@/route-names/public';
import { HostName } from '@/statics/main';

/**
 * Create a new Firebase auth user
 * @param email register email
 * @param password register password
 */
const createFirebaseAuthUser = async (
  email: string,
  password: string
): Promise<firebase.User> => {
  const { user } = await FirebaseAppAuth.createUserWithEmailAndPassword(
    email,
    password
  );

  if (!user) {
    throw new Error('Problem crating user');
  }

  return user;
};

/**
 * Create account.
 * @param registerationUserData User data from registeration form
 */
export const createAccountAction = async (
  registerationUserData: UserRegistrationData
): Promise<LoggedInUser> => {
  const {
    name,
    email,
    companyName,
    password,
    countryCode,
    phoneNumber,
    vatNumber = '',
  } = registerationUserData;

  const user = await createFirebaseAuthUser(email, password);
  const { uid } = user;

  const userInfo: AuthorizedUser = {
    UID: uid,
    EMAIL: email,
    PHONE_NUMBER: phoneNumber,
    COMPANY_NAME: companyName,
    COUNTRY_CODE: countryCode,
    VAT_NUMBER: vatNumber,
    NAME: name,
    ROLES: [USER_ROLES.ADVERTISER],
  };

  await createUserInfo(userInfo);

  return {
    user,
    userInfo,
  };
};

/**
 * Login to Firebase SDK
 * @param loginData login user data
 */
export const loginToFirebase = async (
  loginData: LoginUserData
): Promise<firebase.User> => {
  const { email, password } = loginData;
  const { user } = await FirebaseAppAuth.signInWithEmailAndPassword(
    email,
    password
  );

  if (!user) {
    throw new Error('User not found');
  }

  return user;
};

/**
 * User login
 * @param loginData login user data
 */
export const loginAction = async (
  loginData: LoginUserData
): Promise<LoggedInUser> => {
  const user = await loginToFirebase(loginData);
  const userInfo = await getUserInfoById(user!.uid);
// TODO: remove this section when cache is turned  back on.
  window.localStorage.setItem('user', JSON.stringify(user))
  window.localStorage.setItem('userInfo', JSON.stringify(userInfo))

  return {
    user,
    userInfo,
  };
};

/**
 * Logout
 */
export const logoutAction = async () => {
  // TODO: remove this section when cache is turned  back on.
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('userInfo');
  await store.dispatch('AuthModule/logout');
  await store.dispatch('CampaignWizardModule/resetCampaign');
  await store.dispatch('CampaignModule/resetState');
  await store.dispatch('AdvertiserModule/resetAllCampaigns');
  await store.dispatch('IncomingCampaignsModule/resetAllCampaigns');
  if (!window.location.href.includes('create-campaign')) {
    router.push(publicRouteNames.LOGIN);
  }
};

/**
 * Send forget password reset email.
 * @param email User email
 * @param isRedirectToCampaignWizard Redirect advertiser after reset password to campaign wizard if needed.
 */

export const sendPasswordResetEmailAction = async (email: string, isRedirectToCampaignWizard?: boolean) => {
  const actionCodeSettings = { url:
      `${ HostName }/reset-password?isRedirectToCampaignWizard=true`
  }
  return FirebaseAppAuth.sendPasswordResetEmail(email, isRedirectToCampaignWizard ? actionCodeSettings : null);
};

/**
 * Reset User's password.
 * @param code Firebase oob code
 * @param newPassword New User password
 */
export const verifyCodeAndResetPasswordAction = async (
  code: string,
  newPassword: string
): Promise<LoginUserData> => {
  const email = await FirebaseAppAuth.verifyPasswordResetCode(code);
  await FirebaseAppAuth.confirmPasswordReset(code, newPassword);

  const loginData = {
    email,
    password: newPassword,
  };

  return loginData;
};

/**
 * @param code Firebase oob code
 */

export const verifyResetPasswordCode = (code: string) => {
  return FirebaseAppAuth.verifyPasswordResetCode(code)
}

export const logoutUserAction = async () => {
  await FirebaseAppAuth.signOut();
};
