import i18n from '@/plugins/i18n';
import { MultiLanguage } from '@/types/misc';
export const uppercase = (text: string) => text.toUpperCase();

export const tuncateText = (text: string, stop: number, clamp: number) => {
  return text && text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
};

const getOppositeLanguage = (locale: 'ar' | 'en'): 'ar' | 'en' => {
  const languageMap = { ar: 'en', en: 'ar' };
  return languageMap[locale] as 'ar' | 'en';
};

export const getSecondaryLanguageText = (multiLanguageText?: MultiLanguage) => {
  return multiLanguageText?.[getOppositeLanguage(i18n.locale as 'ar' | 'en')];
};

export const translateToSecondaryLanguage = (text: string) => {
  return i18n.t(text, getOppositeLanguage(i18n.locale as 'ar' | 'en'));
};

export const formatSaudiPhoneNumber = (text: string) => {
  return text.replace(/ /g, '').replace(/^(.{3})(.{4})(.*)$/, '$1 $2 $3');
};

export const pluralizeWeeks = (numberOfWeeks: number) => {
  return pluralizeText(numberOfWeeks, 'weeks', 'week');
};

export const pluralizeDays = (numberOfDays: number) => {
  return pluralizeText(numberOfDays, 'days', 'day');
};

const pluralizeText = (count: number, pluralKey: string, singularKey: string) => {
  const key = count > 1 ? pluralKey : singularKey;
  return { number: count, key };
};
