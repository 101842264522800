import { render, staticRenderFns } from "./MediaCarousel.vue?vue&type=template&id=389e140c&scoped=true"
import script from "./MediaCarousel.vue?vue&type=script&lang=ts"
export * from "./MediaCarousel.vue?vue&type=script&lang=ts"
import style0 from "./MediaCarousel.vue?vue&type=style&index=0&id=389e140c&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "389e140c",
  null
  
)

export default component.exports