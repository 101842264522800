
import { Component, Prop, Vue } from 'vue-property-decorator';
import InfoCircle from '@/ui-components/InfoCircle/InfoCircle.vue';
import { AdvertiserCampaign } from '@/types/campaigns';

@Component({
  components: { InfoCircle },
})
export default class AdvertiserCampaignDetails extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  public campaign!: AdvertiserCampaign;

  public get locations() {
    return this.campaign.locations.map(({ NAME }) => NAME).join(', ');
  }

  public get screensBooked() {
    return 0;
  }

  public get startDate() {
    return this.campaign.startDate;
  }

  public get city() {
    return this.$t(`cities.${this.campaign.locations[0].CITY.DISPLAY_NAME.en?.toLocaleLowerCase()}`);
  }

  public get country() {
    return this.$t(`countries.${this.campaign.locations[0].COUNTRY.DISPLAY_NAME?.toLocaleLowerCase()}`);
  }

  public get name() {
    return this.campaign.name;
  }

  public get endDate() {
    return this.campaign.endDate;
  }

  public get totalPrice() {
    return this.campaign.totalPrice;
  }

  public get durationInWeeks() {
    return this.campaign.durationInWeeks;
  }

  public get expectedViews() {
    return 0;
  }

  public get rejectedReason() {
    return this.campaign.rejectionReason;
  }

  public isOpen = false;

  public open() {
    this.isOpen = true;
  }

  public close() {
    this.isOpen = false;
  }
}
