
import Media from '@/ui-components/Media/Media.vue';
import CampaignStatus from '@/ui-components/CampaignStatus/CampaignStatus.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AdvertiserCampaign } from '@/types/campaigns';
import { SystemStatus } from '@/types/misc';
import { MediaFile } from '@/types/media';
import { SYSTEM_STATUS } from '@/statics/system-status';

@Component({
  name: 'CampaignData',
  components: {
    Media,
    CampaignStatus,
  },
})
export default class CampaignData extends Vue {
  @Prop({ type: Object, default: () => ({}) }) campaign!: AdvertiserCampaign;

  public mediaTooltipMessages: Record<SystemStatus['VAL'], string> = {
    [SYSTEM_STATUS.NEW.VAL]: this.$t('pending_change_media_request_approval_tooltip').toString(),
    [SYSTEM_STATUS.REJECTED.VAL]: this.$t('rejected_tooltip').toString(),
    [SYSTEM_STATUS.APPROVED.VAL]: this.$t('approved_change_media_request_tooltip').toString(),
  }

  public get startDate(): Date {
    return this.campaign.startDate!;
  }

  public get endDate(): Date {
    return this.campaign.endDate!;
  }

  public get name(): string {
    return this.campaign.name!;
  }

  public get status(): SystemStatus {
    return this.campaign.status!;
  }

  public get durationInWeeks(): number {
    return this.campaign.durationInWeeks!;
  }

  public get isRejectedCampaign(): boolean {
    return this.campaign.status.VAL === SYSTEM_STATUS.REQUIRES_ACTION.VAL
      || this.campaign.dbDataObject.MEDIA_CHANGE_REQUEST_STATUS?.VAL === SYSTEM_STATUS.REJECTED.VAL;
  }
  public get rejectionReason(): string {
    return this.campaign.rejectionReason!;
  }

  public get media() {
    if (this.campaign.mediaList && this.campaign.mediaList.length) {
      const [media] = this.campaign.mediaList;
      return media;
    }
    return this.campaign.media;
  }

  public get mediaType(): string {
    return this.media && this.media.type ? this.media.type : 'no_media';
  }

  public get locationsCount(): number {
    return this.campaign.locations.length;
  }

  public get designerEmails(): string[] {
    const result: string[] = [];
    return this.campaign.mediaList.reduce((acc, media: MediaFile) => {
      if (!media.uploadedBy || acc.includes(media.uploadedBy))
        return acc
      acc.push(media.uploadedBy)
      return acc
    }, result);
  }

  public get locationsLabel(): string {
    const extraLetter = this.locationsCount > 1 ? 's' : '';
    return `location${extraLetter}`;
  }

  get isRunningCampaign() {
    return this.status.VAL === SYSTEM_STATUS.RUNNING.VAL
  }

  public get isChangeMediaRequest(): boolean {
    return this.isRunningCampaign && (this.campaign.dbDataObject.MEDIA_CHANGE_REQUEST_STATUS?.VAL === SYSTEM_STATUS.NEW.VAL ||
      this.campaign.dbDataObject.MEDIA_CHANGE_REQUEST_STATUS?.VAL === SYSTEM_STATUS.APPROVED.VAL);
  }

  public get isPendingMediaApproval(): boolean {
    return this.isRunningCampaign && this.campaign.dbDataObject.MEDIA_CHANGE_REQUEST_STATUS?.VAL === SYSTEM_STATUS.NEW.VAL
  }

  public get isMediaApproved(): boolean {
    return this.isRunningCampaign && this.campaign.dbDataObject.MEDIA_CHANGE_REQUEST_STATUS?.VAL === SYSTEM_STATUS.APPROVED.VAL
  }

  public get mediaTooltipMessage(): string {
    if (this.campaign.dbDataObject.MEDIA_CHANGE_REQUEST_STATUS?.VAL)
      return this.mediaTooltipMessages[this.campaign.dbDataObject.MEDIA_CHANGE_REQUEST_STATUS?.VAL]
    return this.$t('rejected_tooltip').toString();
  }
}
