
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { createFileRef } from '@/actions/media/actions';
import { signInAnonymously } from '@/actions/users/actions';
import { MediaFile, MediaResolution } from '@/types/media';
import { Nullable } from '@/types/misc';
import {
  checkFileSize,
  checkFileType,
  checkMediaResolution,
  checkVideoDuration,
  getMediaFileSizeLimit,
  getVideoMediaDuration,
} from '@/utils/files';
import namespaces from '@/store/namespaces';
import { ShowSnackbar } from '@/types/snackbar';
import { Action } from 'vuex-class';
import IButton from '@/ui-components/IButton/IButton.vue';
import Media from '@/ui-components/Media/Media.vue';
import MediaZoomDialog from '@/dialogs/shared/MediaZoomDialog.vue';
import { SUPPORTED_TYPES } from '@/statics/supported-types';
import { v4 as uuidv4 } from 'uuid';

@Component({
  components: {
    IButton,
    Media,
    MediaZoomDialog,
  },
})
export default class Upload extends Vue {
  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  @Prop({ type: Array, default: () => [] }) acceptedFileTypes!: string[];
  @Prop({ type: Object, default: () => ({}) })
  acceptedResolution!: MediaResolution;
  @Prop({ type: Boolean, default: false }) isAuthenticated!: boolean;
  @Prop({ type: Boolean, default: false }) readOnly!: boolean;
  @Prop({ type: Object }) selectedMediaFile!: MediaFile;

  public get isFileExist() {
    return this.selectedMediaFile && this.selectedMediaFile.path;
  }

  get resolution(): string {
    const { width, height, unit } = this.acceptedResolution;
    return `${this.$t('width')} ${width} x ${this.$t('height')} ${height} ${unit}`;
  }

  public get isUploading() {
    return this.uploadStatus === 'UPLOADING';
  }

  public get isNotUploading() {
    return this.uploadStatus === null;
  }

  public get supportedFileTypes() {
    return this.acceptedFileTypes
      .map((type) => type.toLocaleUpperCase())
      .join(', ');
  }

  public get supportedFileExtensions() {
    return this.acceptedFileTypes
      .map((type) => `.${type.toLocaleLowerCase()}`)
      .join(', ');
  }

  public selectedMediaThumbnail: Nullable<MediaFile> = null;
  public selectedMediaType: Nullable<string> = null;
  public selectedMediaSnapshot: any = null;
  public selectedMediaName: Nullable<string> = null;

  public uploadStatus: Nullable<string> = null;
  public uploadProgress = 20;

  public openMediaZoomDialog() {
    const dialog = this.$refs.mediaZoomDialogRef as MediaZoomDialog;
    dialog.open();
  }

  public browse() {
    const fileInputElement = this.$refs.uploader as HTMLInputElement;
    fileInputElement.click();
  }

  public async uploadMedia(file: File): Promise<void> {
    this.uploadStatus = 'UPLOADING';

    if (!this.isAuthenticated) {
      await signInAnonymously();
    }
    const mediaId = uuidv4()
    const ref = await createFileRef(file, mediaId);
    this.selectedMediaSnapshot = ref.put(file);

    this.selectedMediaSnapshot!.on(
      'state_changed',
      (snapshot: any) => {
        this.uploadProgress = Math.ceil(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error: Error) => {
        /** */
      },
      async () => {
        const path = await this.selectedMediaSnapshot.snapshot.ref.getDownloadURL();
        const type = file.type.split('/')[0];
        const mime = file.type;
        const { name } = file;
        const anonymous = !this.isAuthenticated;
        const mediaFile = {
          ...this.selectedMediaFile,
          name,
          path,
          type,
          mime,
          anonymous,
          ref,
          mediaId,
        };

        if (file.type === SUPPORTED_TYPES.MP4) {
          mediaFile.playBackDuration = await getVideoMediaDuration(file);
        }

        this.updateMediaFile(mediaFile);
        this.uploadStatus = null;
      }
    );
  }

  public removeSelectedFile() {
    this.selectedMediaType = null;
    this.selectedMediaName = null;
    this.uploadStatus = null;
    this.updateMediaFile({
      ...this.selectedMediaFile,
      path: undefined,
      ref: undefined,
      type: undefined,
      name: undefined,
    });
    this.browse();
  }

  @Emit('change')
  @Emit('update:selectedMediaFile')
  public updateMediaFile(file: null | MediaFile) {
    return file;
  }

  public cancelFileUpload() {
    try {
      this.selectedMediaSnapshot.cancel();
      this.uploadStatus = null;
      this.uploadProgress = 0;
    } catch {
      /** */
    }
  }

  public async handleMediaUploadError({ message }: Error) {
    const sizeLimit = await getMediaFileSizeLimit();
    const text = this.$t(`errors.${ message }`, { sizeLimit }) as string;
    const color = 'danger';
    this.showSnackbar({ text, color });
  }

  public async handleMediaSelect(event: any) {
    const maxSize = await getMediaFileSizeLimit();
    const files =
      event.target.files && event.target.files.length
        ? event.target.files
        : event.dataTransfer.files;
    try {
      const [file] = files;
      if (file) {
        checkFileSize(file, maxSize);
        checkFileType(file, this.acceptedFileTypes);
        await checkMediaResolution(file, this.acceptedResolution);
        await checkVideoDuration(file);
        this.setSelectedMediaMetaData(file);
        await this.uploadMedia(file);
        this.clearFileInput();
      }
    } catch (error) {
      await this.handleMediaUploadError(error as Error);
    }
  }

  private setSelectedMediaMetaData(file: File) {
    const { type, name } = file;
    this.selectedMediaName = name;
    this.selectedMediaThumbnail = {
      name: file.name,
      path: URL.createObjectURL(file),
    };
    this.selectedMediaType = type;
  }

  private clearFileInput() {
    const fileInputElement = this.$refs.uploader as HTMLInputElement;
    fileInputElement.value = '';
  }

  public openGuideLinesModal() {}
}
