import { RunningCampaign, StoreCampaign, StoreOwnerCampaign, StoreOwnerIncomingCampaign } from '@/types/campaigns';
import { LocationData } from '@/types/locations';
import { ScreenCluster } from '@/types/screen-cluster';
import { PendingCampaign, CompletedCampaign } from '@/types/campaigns';
import {
  isCampaignPendingStoreOwnerApproval,
  sortCampaignByStartDate,
  isCampaignRunning,
  filterStoreCampaignData,
  extractStoreCampaignData,
  isCampaignCompleted,
  sortPendingStoreOwnerApprovalCampaignByRequestTime,
  isCampaignUpcoming,
  extractShashaAds,
  extractIncomingStoreOwnerCampaignDataForStoreOwner,
  extractIncomingStoreOwnerCampaignDataForRegulator,
} from '@/utils/campaigns';
import { IncomingCampaignsState } from './types';
import {
  approveCampaignMediaChangeAction,
  loadAdminCampaigns,
  loadCompletedStoreOwnerCampaigns,
  loadStoreOwnerCampaigns,
  rejectCampaignAction,
  rejectCampaignMediaChangeAction,
} from '@/actions/campaigns/store-owner';
import { approveCampaignAction } from '@/actions/campaigns/store-owner';
import { loadStoreCampaigns } from '@/actions/campaigns/store-owner';
import { RootState } from '@/store/types';
import { ActionTree, ActionContext, Dispatch } from 'vuex';
import { getUserById, getUsersInfoByIDs } from '@/actions/users/actions';
import {
  fetchStoreOwnersUsersIdsMapFromStoreOwnerCampaigns,
  loadAdvertiserCampaignsForRegulator,
  loadRegulatorStoreOwnerCampaigns
} from '@/actions/campaigns/regulator';
import { SYSTEM_STATUS } from '@/statics/system-status';
import { getCurrentUser } from '@/firebase/firebase-user';
import { USER_ROLES } from '@/statics/user-roles';

const checkAndLoadStoreScreenClusters = async (
  rootGetters: any,
  dispatch: Dispatch
) => {
  const storeScreenClusters = rootGetters['StoreModule/screenClusters'];

  if (!storeScreenClusters || !storeScreenClusters.length) {
    await dispatch('StoreModule/loadStoreScreenClusters', null, { root: true });
  }
};

const checkAndLoadRegulatorScreenClusters = async (
  rootGetters: any,
  dispatch: Dispatch
) => {
  const regulatorScreenClusters = rootGetters['StoreModule/screenClusters'];

  if (!regulatorScreenClusters || !regulatorScreenClusters.length) {
    await dispatch('StoreModule/loadRegulatorScreenClusters', null, { root: true });
  }
};

const checkAndLoadLocations = async (rootGetters: any, dispatch: Dispatch) => {
  const locations = rootGetters['CommonModule/locations'];
  const currentUser = await getCurrentUser();
  const loggedInUser = await getUserById(currentUser!.uid)
    const [userRole] = loggedInUser!.ROLES;
  if (!locations || !locations.length) {
    switch (userRole.VAL) {
      case (USER_ROLES.STORE_OWNER.VAL): {
        await dispatch('CommonModule/loadStoreOwnerLocations', null, { root: true });
        break
      }
      case (USER_ROLES.REGULATOR.VAL): {
        await dispatch('CommonModule/loadRegulatorLocations', null, { root: true });
        break
      }
      default: {
        await dispatch('CommonModule/loadLocations', null, { root: true });
        break
      }
    }

  }
};

export const actions: ActionTree<IncomingCampaignsState, RootState> = {
  resetAllCampaigns: async ({
                              commit,
                            }: ActionContext<IncomingCampaignsState, RootState>) => {
    commit('resetAllCampaigns');
  },

  loadPendingCampaigns: async ({
                                 commit,
                                 rootGetters,
                                 dispatch,
                               }: ActionContext<IncomingCampaignsState, RootState>) => {
    await checkAndLoadStoreScreenClusters(rootGetters, dispatch);
    await checkAndLoadLocations(rootGetters, dispatch);

    const storeScreenClusters: ScreenCluster[] = rootGetters['StoreModule/screenClusters'];
    const locationsList: LocationData[] = rootGetters['CommonModule/locations'];

    const campaigns = await loadStoreCampaigns();

    const campaignsAdvertisersUIDs = campaigns.map(
      ({ ADVERTISER_UID }) => ADVERTISER_UID
    );
    const campaignsAdvertisersList = await getUsersInfoByIDs(
      campaignsAdvertisersUIDs
    );

    const mappedPendingCampaigns: PendingCampaign[] = campaigns
      .filter(isCampaignPendingStoreOwnerApproval)
      .sort(sortPendingStoreOwnerApprovalCampaignByRequestTime)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractStoreCampaignData(campaign as StoreOwnerIncomingCampaign, campaignsAdvertisersList, locationsList));

    commit('setPendingCampaigns', mappedPendingCampaigns);
  },
  loadUpcomingCampaigns: async ({
                                  commit,
                                  rootGetters,
                                  dispatch,
                                }: ActionContext<IncomingCampaignsState, RootState>)=>{
    await checkAndLoadStoreScreenClusters(rootGetters, dispatch);
    await checkAndLoadLocations(rootGetters, dispatch);

    const storeScreenClusters: ScreenCluster[] = rootGetters['StoreModule/screenClusters'];
    const locationsList: LocationData[] = rootGetters['CommonModule/locations'];
    const currentUser = await getCurrentUser();
    const storeOwner = await getUserById(currentUser!.uid)
    const campaigns = await loadStoreCampaigns();
    const storeOwnerCampaigns = await loadStoreOwnerCampaigns(storeScreenClusters.map(({ ID }) => ID), SYSTEM_STATUS.UPCOMING);
    const adminCampaigns = await loadAdminCampaigns(locationsList.map(({ ID }) => ID), SYSTEM_STATUS.UPCOMING);
    const campaignsAdvertisersUIDs = campaigns.map(
      ({ ADVERTISER_UID }) => ADVERTISER_UID
    );
    const campaignsAdvertisersList = await getUsersInfoByIDs(
      campaignsAdvertisersUIDs
    );
    const mappedRunningCampaigns: PendingCampaign[] = campaigns
      .filter(isCampaignUpcoming)
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractStoreCampaignData(campaign as StoreOwnerIncomingCampaign, campaignsAdvertisersList, locationsList));

    const mappedStoreOwnerCampaigns: PendingCampaign[] = storeOwnerCampaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractIncomingStoreOwnerCampaignDataForStoreOwner(campaign,storeScreenClusters, locationsList, storeOwner));

    const mappedAdminCampaigns: PendingCampaign[] = adminCampaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractShashaAds(campaign,storeScreenClusters, locationsList));

    commit('setUpcomingCampaigns', [...mappedRunningCampaigns, ...mappedStoreOwnerCampaigns, ...mappedAdminCampaigns]);
  },
  loadRegulatorUpcomingCampaigns: async ({
                                           commit,
                                           rootGetters,
                                           dispatch,
                                         }: ActionContext<IncomingCampaignsState, RootState>) => {
    await checkAndLoadRegulatorScreenClusters(rootGetters, dispatch);
    await checkAndLoadLocations(rootGetters, dispatch);

    const storeScreenClusters: ScreenCluster[] = rootGetters['StoreModule/screenClusters'];
    const locationsList: LocationData[] = rootGetters['CommonModule/locations'];
    const storeOwnerCampaigns = await loadRegulatorStoreOwnerCampaigns(SYSTEM_STATUS.UPCOMING.VAL)
    const campaigns = await loadAdvertiserCampaignsForRegulator(SYSTEM_STATUS.UPCOMING.VAL);
    const campaignsAdvertisersUIDs = campaigns.map(
      ({ ADVERTISER_UID }) => ADVERTISER_UID
    );
    const campaignsAdvertisersList = await getUsersInfoByIDs(
      campaignsAdvertisersUIDs
    );
    const storeOwnersUsersIdsMap = await fetchStoreOwnersUsersIdsMapFromStoreOwnerCampaigns(storeOwnerCampaigns as unknown as StoreOwnerCampaign[])
    const adminCampaigns = await loadAdminCampaigns(locationsList.map(({ ID }) => ID), SYSTEM_STATUS.UPCOMING);
    const mappedAdminCampaigns: PendingCampaign[] = adminCampaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractShashaAds(campaign as StoreOwnerIncomingCampaign ,storeScreenClusters, locationsList));
    const mappedStoreOwnerCampaigns: PendingCampaign[] = storeOwnerCampaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractIncomingStoreOwnerCampaignDataForRegulator(campaign, storeScreenClusters, locationsList, storeOwnersUsersIdsMap));

    const mappedPendingCampaigns: PendingCampaign[] = campaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractStoreCampaignData(campaign as StoreOwnerIncomingCampaign, campaignsAdvertisersList, locationsList));
    commit('setUpcomingCampaigns', [...mappedPendingCampaigns, ...mappedAdminCampaigns, ...mappedStoreOwnerCampaigns]);
  },

  loadCompletedCampaigns: async ({
                                   commit,
                                   rootGetters,
                                   dispatch,
                                 }: ActionContext<IncomingCampaignsState, RootState>) => {
    await checkAndLoadStoreScreenClusters(rootGetters, dispatch);
    await checkAndLoadLocations(rootGetters, dispatch);

    const storeScreenClusters: ScreenCluster[] = rootGetters['StoreModule/screenClusters'];
    const locationsList: LocationData[] = rootGetters['CommonModule/locations'];
    const currentUser = await getCurrentUser();
    const storeOwner = await getUserById(currentUser!.uid);

    const campaigns = await loadStoreCampaigns();
    const adminCampaigns = await loadAdminCampaigns(locationsList.map(({ ID }) => ID),SYSTEM_STATUS.FINISHED);
    const campaignsAdvertisersUIDs = campaigns.map(
      ({ ADVERTISER_UID }) => ADVERTISER_UID
    );
    const campaignsAdvertisersList = await getUsersInfoByIDs(
      campaignsAdvertisersUIDs
    );
    const storeOwnerCampaigns = await loadCompletedStoreOwnerCampaigns(storeScreenClusters.map(({ ID }) => ID));
    const mappedCompletedCampaigns: CompletedCampaign[] = campaigns
      .filter(isCampaignCompleted)
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractStoreCampaignData(campaign as StoreOwnerIncomingCampaign, campaignsAdvertisersList, locationsList));

    const mappedAdminCampaigns: PendingCampaign[] = adminCampaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractShashaAds(campaign, storeScreenClusters, locationsList));
    const mappedStoreOwnerCampaigns: PendingCampaign[] = storeOwnerCampaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractIncomingStoreOwnerCampaignDataForStoreOwner(campaign, storeScreenClusters, locationsList, storeOwner));

    commit('setCompletedCampaigns', [...mappedCompletedCampaigns, ...mappedStoreOwnerCampaigns, ...mappedAdminCampaigns]);
  },
  loadRegulatorCompletedCampaigns: async ({
                                            commit,
                                            rootGetters,
                                            dispatch,
                                          }: ActionContext<IncomingCampaignsState, RootState>) => {
    await checkAndLoadRegulatorScreenClusters(rootGetters, dispatch);
    await checkAndLoadLocations(rootGetters, dispatch);

    const storeScreenClusters: ScreenCluster[] = rootGetters['StoreModule/screenClusters'];
    const locationsList: LocationData[] = rootGetters['CommonModule/locations'];

    const campaigns = await loadAdvertiserCampaignsForRegulator(SYSTEM_STATUS.FINISHED.VAL);
    const adminCampaigns = await loadAdminCampaigns(locationsList.map(({ ID }) => ID),SYSTEM_STATUS.FINISHED);
    const campaignsAdvertisersUIDs = campaigns.map(
      ({ ADVERTISER_UID }) => ADVERTISER_UID
    );
    const campaignsAdvertisersList = await getUsersInfoByIDs(
      campaignsAdvertisersUIDs
    );
    const storeOwnerCampaigns = await loadRegulatorStoreOwnerCampaigns(SYSTEM_STATUS.FINISHED.VAL)
    const storeOwnersUsersIdsMap = await fetchStoreOwnersUsersIdsMapFromStoreOwnerCampaigns(storeOwnerCampaigns as unknown as StoreOwnerCampaign[])
    const mappedCompletedCampaigns: CompletedCampaign[] = campaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractStoreCampaignData(campaign as StoreOwnerIncomingCampaign, campaignsAdvertisersList, locationsList));
    const mappedAdminCampaigns: PendingCampaign[] = adminCampaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractShashaAds(campaign as StoreOwnerIncomingCampaign,storeScreenClusters, locationsList));
    const mappedStoreOwnerCampaigns: PendingCampaign[] = storeOwnerCampaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractIncomingStoreOwnerCampaignDataForRegulator(campaign, storeScreenClusters, locationsList, storeOwnersUsersIdsMap));

    commit('setCompletedCampaigns', [...mappedCompletedCampaigns, ...mappedAdminCampaigns, ...mappedStoreOwnerCampaigns]);
  },

  loadRunningCampaigns: async ({
                                 commit,
                                 rootGetters,
                                 dispatch,
                               }: ActionContext<IncomingCampaignsState, RootState>) => {
    await checkAndLoadStoreScreenClusters(rootGetters, dispatch);
    await checkAndLoadLocations(rootGetters, dispatch);

    const storeScreenClusters: ScreenCluster[] = rootGetters['StoreModule/screenClusters'];
    const locationsList: LocationData[] = rootGetters['CommonModule/locations'];

    const currentUser = await getCurrentUser();
    const storeOwner = await getUserById(currentUser!.uid)

    const campaigns = await loadStoreCampaigns();
    const storeOwnerCampaigns = await loadStoreOwnerCampaigns(storeScreenClusters.map(({ ID }) => ID),SYSTEM_STATUS.RUNNING);
    const adminCampaigns = await loadAdminCampaigns(locationsList.map(({ ID }) => ID), SYSTEM_STATUS.RUNNING);
    const campaignsAdvertisersUIDs = campaigns.map(
      ({ ADVERTISER_UID }) => ADVERTISER_UID
    );
    const campaignsAdvertisersList = await getUsersInfoByIDs(
      campaignsAdvertisersUIDs
    );
    const mappedRunningCampaigns: PendingCampaign[] = campaigns
      .filter(isCampaignRunning)
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractStoreCampaignData(campaign as StoreOwnerIncomingCampaign, campaignsAdvertisersList, locationsList));

    const mappedStoreOwnerCampaigns: PendingCampaign[] = storeOwnerCampaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractIncomingStoreOwnerCampaignDataForStoreOwner(campaign, storeScreenClusters, locationsList, storeOwner));

    const mappedAdminCampaigns: PendingCampaign[] = adminCampaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractShashaAds(campaign, storeScreenClusters, locationsList));

    commit('setRunningCampaigns', [...mappedRunningCampaigns, ...mappedStoreOwnerCampaigns, ...mappedAdminCampaigns]);
  },
  loadRegulatorRunningCampaigns: async ({
                                          commit,
                                          rootGetters,
                                          dispatch,
                                        }: ActionContext<IncomingCampaignsState, RootState>) => {
    await checkAndLoadRegulatorScreenClusters(rootGetters, dispatch);
    await checkAndLoadLocations(rootGetters, dispatch);

    const storeScreenClusters: ScreenCluster[] = rootGetters['StoreModule/screenClusters'];
    const locationsList: LocationData[] = rootGetters['CommonModule/locations'];
    const campaigns = await loadAdvertiserCampaignsForRegulator(SYSTEM_STATUS.RUNNING.VAL);
    const storeOwnerCampaigns = await loadRegulatorStoreOwnerCampaigns(SYSTEM_STATUS.RUNNING.VAL)
    const adminCampaigns = await loadAdminCampaigns(locationsList.map(({ ID }) => ID), SYSTEM_STATUS.RUNNING);
    const campaignsAdvertisersUIDs = campaigns.map(
      ({ ADVERTISER_UID }) => ADVERTISER_UID
    );
    const campaignsAdvertisersList = await getUsersInfoByIDs(
      campaignsAdvertisersUIDs
    );
    const storeOwnersUsersIdsMap = await fetchStoreOwnersUsersIdsMapFromStoreOwnerCampaigns(storeOwnerCampaigns as unknown as StoreOwnerCampaign[])
    const mappedRunningCampaigns: RunningCampaign[] = campaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractStoreCampaignData(campaign as StoreOwnerIncomingCampaign, campaignsAdvertisersList, locationsList));

    const mappedStoreOwnerCampaigns: PendingCampaign[] = storeOwnerCampaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractIncomingStoreOwnerCampaignDataForRegulator(campaign, storeScreenClusters, locationsList, storeOwnersUsersIdsMap));

    const mappedAdminCampaigns: RunningCampaign[] = adminCampaigns
      .sort(sortCampaignByStartDate)
      .map((campaign) => filterStoreCampaignData(campaign, storeScreenClusters, locationsList))
      .map((campaign) => extractShashaAds(campaign,storeScreenClusters, locationsList));
    commit('setRunningCampaigns', [...mappedRunningCampaigns,...mappedStoreOwnerCampaigns, ...mappedAdminCampaigns]);
  },

  approveCampaign: async (
    { commit }: ActionContext<IncomingCampaignsState, RootState>,
    campaign: StoreCampaign
  ) => {
    const { campaignRequest } = campaign;
    await approveCampaignAction(campaignRequest);
    commit('omitPendingCampaign', campaignRequest);
    commit('pushToRunningCampaigns', campaign);
  },

  rejectCampaign: async (
    { commit }: ActionContext<IncomingCampaignsState, RootState>,
    { campaignRequest, rejectionReason }
  ) => {
    await rejectCampaignAction(campaignRequest, rejectionReason);
    commit('omitPendingCampaign', campaignRequest);
  },

  approveCampaignMediaChange: async (
    { commit }: ActionContext<IncomingCampaignsState, RootState>,
    campaign: StoreCampaign,
  ) => {
    const { campaignRequest, mediaList } = campaign;
    await approveCampaignMediaChangeAction(campaignRequest, mediaList!);
    commit('omitPendingCampaign', campaignRequest);
    commit('updateRunningCampaign', campaign);
  },

  rejectCampaignMediaChange: async (
    { commit }: ActionContext<IncomingCampaignsState, RootState>,
    { campaignRequest, rejectionReason }
  ) => {
    await rejectCampaignMediaChangeAction(campaignRequest, rejectionReason);
    commit('omitPendingCampaign', campaignRequest);
  },
  setActiveTab: (
    { commit }: ActionContext<IncomingCampaignsState, RootState>,
    activeTab: string) => {
    commit('updateActiveTab', activeTab);
  },
  setSelectedPage: (
    { commit }: ActionContext<IncomingCampaignsState, RootState>,
    selectedPage: number) => {
    commit('updateSelectedPage', selectedPage);
  },
};
