
import { Component, Prop, Vue } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';
import TextField from '@/ui-components/TextField/TextField.vue';
import Checkbox from '@/ui-components/Checkbox/Checkbox.vue';
import { rules } from '@/validation-rules';
import { PayLaterPaymentInfo } from '@/types/payment';
import { Campaign } from '@/types/campaigns';
import { SYSTEM_STATUS } from '@/statics/system-status';
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { UpdateBillingContact } from '@/store/modules/campaign-wizard/types';
import { publicRouteNames } from '@/route-names/public';
import firebaseNames from '@/statics/firebase-names';
import { FirebaseRemoteConfig } from '@/firebase/firebase-app';
import PaymentTermsAndPrivacy from '@/pages/shared/CampaignWizard/components/Payment/components/PaymentTermsAndPrivacy.vue';
import moment from 'moment';
import firebase from 'firebase';
import firestore = firebase.firestore;

@Component({
  components: {
    IButton,
    TextField,
    Checkbox,
    PaymentTermsAndPrivacy,
  },
})
export default class PayLater extends Vue {
  @Prop({ required: true, default: () => ({}) }) public billingInfo!: PayLaterPaymentInfo;
  @Prop({ required: true }) public campaignId!: Campaign['ID'];
  public valid = false;
  public terms = false;

  @Action('updateCampaign', { namespace: namespaces.CommonModule })
  public updateMyCampaign!: any;

  @Action('updateBillingContact', { namespace: namespaces.CampaignWizardModule })
  public updateBillingContact!: UpdateBillingContact;


  loading = false;

  get emailRules() {
    return [
      rules.required(this.$i18n.t('please_enter_email') as string),
      rules.emailFormat(this.$i18n.t('invalid_email') as string),
    ];
  }

  get phoneNumberRules() {
    return [
      rules.required(this.$i18n.t('please_enter_mobile_number') as string),
      rules.saudiPhoneFormat(this.$i18n.t('phone_not_saudi_valid') as string),
    ];
  }

  async payLaterCampaign() {
    await FirebaseRemoteConfig.fetchAndActivate();
    const payLaterEnabled = FirebaseRemoteConfig.getBoolean(firebaseNames.remoteConfig.PAY_LATER_FOR_CAMPAIGN)
    const pendingPaymentTimeoutInHours = FirebaseRemoteConfig.getNumber(firebaseNames.remoteConfig.PENDING_PAYMENT_TIMEOUT_IN_HOURS)

    if (payLaterEnabled) {
      const reservedUntil = moment().add(pendingPaymentTimeoutInHours, 'hours').toDate();
      await this.updateMyCampaign({
        ID: this.campaignId,
        STATUS: SYSTEM_STATUS.PENDING_PAYMENT,
        RESERVED_UNTIL: firestore.Timestamp.fromDate(reservedUntil),
        SEND_QUOTATION_EMAIL: true,
        PAYMENT_REFERENCE: firestore.FieldValue.delete(),
      });
    }
  }
  async submitCampaign() {
    try {
      if (!this.terms) {
        return;
      }
      this.loading = true;
      await this.updateBillingContact(this.billingInfo);
      await this.payLaterCampaign();
      this.navigateToCampaignSubmitPage();
      this.loading = false;
    } catch (err) {
      console.log(`Couldn't reserve timeslots for pending payment campaign ${ this.campaignId }`)
      throw new Error(this.$t('something_went_wrong').toString() + '::' + (err as Error).message);
    }
  }

  navigateToCampaignSubmitPage() {
    this.$router.push({
      ...publicRouteNames.CAMPAIGN_SUBMIT, params: {
        id: this.campaignId,
        method: 'later',
        billingInfoEmail: this.billingInfo.email,
        billingInfoPhone: this.billingInfo.phone,
      }
    });
  }
}
