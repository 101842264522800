import { render, staticRenderFns } from "./UpcomingCampaignsTable.vue?vue&type=template&id=77b3c779&scoped=true"
import script from "./UpcomingCampaignsTable.vue?vue&type=script&lang=ts"
export * from "./UpcomingCampaignsTable.vue?vue&type=script&lang=ts"
import style0 from "./UpcomingCampaignsTable.vue?vue&type=style&index=0&id=77b3c779&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77b3c779",
  null
  
)

export default component.exports