
import { Component, Vue } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';
import IDialog from '@/ui-components/IDialog/IDialog.vue';

@Component({
  name: "ConfirmTodayDate",
  components: {IButton, IDialog},
})
export default class TodayStartDateConfirmationDialog extends Vue {
  public isOpen = false;

  public open() {
    this.isOpen = true;
  }
  public close() {
      this.isOpen = false;
  }
}
