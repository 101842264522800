import { publicRouteNames } from '@/route-names/public';
import { sharedRouteNames } from '@/route-names/shared';

const SharedLayout = () => import('@/layouts/SharedLayout/SharedLayout.vue');
const RegisterPage = () => import('@/pages/public/Register/Register.vue');
const LoginPage = () => import('@/pages/public/Login/Login.vue');
const AdminLogin = () => import('@/pages/public/AdminLogin/AdminLogin.vue');
const ForgotPasswordPage = () => import('@/pages/public/ForgotPassword/ForgotPassword.vue');
const ResetPasswordPage = () => import('@/pages/public/ResetPassword/ResetPassword.vue');
const DesignUpload = () => import('@/pages/public/DesignUpload/DesignUpload.vue');
const UploadSuccess = () => import('@/pages/public/UploadSuccess/UploadSuccess.vue');
const PageNotFound = () => import('@/pages/shared/PageNotFound/PageNotFound.vue');
const CampaignWizard = () => import('@/pages/shared/CampaignWizard/CampaignWizard.vue');
const CampaignSubmit = () => import('@/pages/shared/CampaignSubmit/CampaignSubmit.vue');

export const publicRoutes = [
  {
    path: '/',
    component: SharedLayout,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        ...publicRouteNames.REGISTER,
        path: '/register',
        component: RegisterPage,
        children: [],
      },
      {
        ...publicRouteNames.LOGIN,
        path: '/login',
        component: LoginPage,
        children: [],
      },
      {
        ...publicRouteNames.ADMIN_LOGIN,
        path: '/admin-login/:token/:userRole',
        component: AdminLogin,
        children: [],
        meta: {
          shared: true,
        },
      },
      {
        ...publicRouteNames.FORGOT_PASSWORD,
        path: '/forgot-password/:isRedirectToCampaignWizard',
        component: ForgotPasswordPage,
        children: [],
      },
      {
        ...publicRouteNames.RESET_PASSWORD,
        path: '/reset-password',
        component: ResetPasswordPage,
        children: [],
        meta: {
          shared: true,
        },
      },
      {
        ...sharedRouteNames.DESIGN_UPLOAD,
        path: '/design-upload/:campaignId/:advertiserUid/:uid',
        component: DesignUpload,
        children: [],
        meta: {
          shared: true,
        },
      },{
        ...sharedRouteNames.UPLOAD_SUCCESS,
        path: '/upload-success',
        component: UploadSuccess,
        children: [],
        meta: {
          shared: true,
        },
      },{
        ...sharedRouteNames.PAGE_NOT_FOUND,
        path: '/404',
        component: PageNotFound,
        children: [],
        meta: {
          shared: true,
        },
      },
      {
        ...sharedRouteNames.CREATE_CAMPAIGN,
        path: '/create-campaign',
        component: CampaignWizard,
        children: [],
        meta: {
          shared: true,
        },
      },
      {
        ...sharedRouteNames.ADVERTISER_EDIT_CAMPAIGN,
        path: '/edit-campaign',
        component: CampaignWizard,
        children: [],
        meta: {
          shared: true,
        },
      },
      {
        ...sharedRouteNames.CAMPAIGN_SUBMIT,
        path: '/campaign-submit/:id/:method',
        component: CampaignSubmit,
        children: [],
        meta: {
          shared: true,
        },
      },
      {
        path: '*',
        redirect: '/login',
      },
    ]
  },
];
