import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import { LocalKeys } from '@/types/misc';

const direction = {
  ar: 'rtl',
  en: 'ltr',
};

export const toggleLanguage = () => {

  const locale = i18n.availableLocales.find(innerLocale => innerLocale !== i18n.locale) as LocalKeys;
  i18n.locale = locale; // Change the locale
  vuetify.framework.rtl = locale === 'ar';
  localStorage.setItem('language', locale); // Store language in local storage
   document.documentElement.setAttribute('dir', direction[locale]); // Set direction
};

export const initializeLanguageAndDirection = () => {
  const defaultLanguage = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en';
  const savedLanguage = (localStorage.getItem('language') || defaultLanguage) as LocalKeys;
  i18n.locale = savedLanguage || defaultLanguage; // Set locale from local storage
  vuetify.framework.rtl = savedLanguage === 'ar';
  document.documentElement.setAttribute('dir', direction[savedLanguage]); // Set direction
};