import { AdvertiserCampaign } from '@/types/campaigns';
import { CampaignPriceInfo } from '@/types/campaigns';
import { checkoutCampaign, saveStoreOwnerCampaign } from '@/actions/campaigns/actions';
import { Campaign } from '@/types/campaigns';
import { AuthorizedUser } from '@/types/users';
import { PaymentData, RecentBillingContact } from '@/types/payment';
import { TimeSlotSelection } from '@/types/time-slots';
import { LocationData, LocationPrice } from '@/types/locations';
import { RootState } from '@/store/types';
import { ActionContext, ActionTree } from 'vuex';
import { CampaignWizardState } from './types';
import { MediaData, MediaFile } from '@/types/media';
import { saveCampaign, updateCampaign } from '@/actions/campaigns/actions';
import { updateRecentBillingContact } from '@/actions/company-profiles/actions';
import { OnlyRequired } from '@/types/misc';
import { PromoCodeData } from '@/types/promotions';
import moment from 'moment';
import { defaultCampaignFrequency } from '@/statics/campaign-wizard';

const getUpdatedPromoCodeData = (
  promoCode: PromoCodeData,
  paymentBill: CampaignPriceInfo
): PromoCodeData => {
  if (!promoCode.code) {
    return promoCode;
  }

  const { ERROR_MESSAGE: error } = paymentBill;
  const status = error ? 'ERROR' : 'APPLIED';

  return {
    ...promoCode,
    error,
    status,
  };
};

export const actions: ActionTree<CampaignWizardState, RootState> = {
  updateLocations: async (
    context: ActionContext<CampaignWizardState, RootState>,
    locationData: LocationData[]
  ) => {
    context.commit('updateLocations', locationData);
    context.commit('syncRequiredMediaResolutions');
  },
  selectLocation: async (
    context: ActionContext<CampaignWizardState, RootState>,
    locationData: LocationData
  ) => {
    // TODO: Sync common locations to selected locations of campaign wizard
    // context.rootState.commonModule.locations
    context.commit('selectLocation', locationData);
    context.commit('syncRequiredMediaResolutions');
  },
  selectLocations: async (
    context: ActionContext<CampaignWizardState, RootState>,
    locationsData: LocationData[]
  ) => {
    context.commit('selectLocations', locationsData);
    context.commit('syncRequiredMediaResolutions');
  },
  syncSelectedLocations: async (
    context: ActionContext<CampaignWizardState, RootState>,
    locations: LocationData[]
  ) => {
    context.commit('syncSelectedLocations', locations);
    context.commit('syncRequiredMediaResolutions');
  },
  deselectLocation: async (
    context: ActionContext<CampaignWizardState, RootState>,
    locationData: LocationData
  ) => {
    context.commit('deselectLocation', locationData);
    context.commit('syncRequiredMediaResolutions');
  },
  deselectAllLocations: async (
    context: ActionContext<CampaignWizardState, RootState>
  ) => {
    context.commit('deselectAllLocations');
    context.commit('syncRequiredMediaResolutions');
  },
  updateName: async (
    context: ActionContext<CampaignWizardState, RootState>,
    name: string
  ) => {
    context.commit('updateName', name);
  },
  updateAdvertiserName: async (
    context: ActionContext<CampaignWizardState, RootState>,
    advertiserName: string
  ) => {
    context.commit('updateAdvertiserName', advertiserName);
  },
  updateLocationPrices: async (
    context: ActionContext<CampaignWizardState, RootState>,
    locationPrices: LocationPrice[]
  ) => {
    context.commit('updateLocationPrices', locationPrices);
  },
  updateDesignerEmail: async (
    context: ActionContext<CampaignWizardState, RootState>,
    email: string
  ) => {
    context.commit('updateDesignerEmail', email);
  },
  updateTime: async (
    context: ActionContext<CampaignWizardState, RootState>,
    timeSlotSelection: TimeSlotSelection
  ) => {
    context.commit('updateTime', timeSlotSelection);
  },
  updatePayment: async (
    context: ActionContext<CampaignWizardState, RootState>,
    paymentData: PaymentData
  ) => {
    context.commit('updatePayment', paymentData);
  },
  updateUser: async (
    context: ActionContext<CampaignWizardState, RootState>,
    user: AuthorizedUser
  ) => {
    context.commit('updateUser', user);
  },

  updateMedia: async (
    context: ActionContext<CampaignWizardState, RootState>,
    mediaData: MediaData
  ) => {
    context.commit('updateMedia', mediaData);
  },

  setActivity: async (
    context: ActionContext<CampaignWizardState, RootState>,
    active: boolean
  ) => {
    context.commit('setActivity', active);
  },

  updateStep: async (
    context: ActionContext<CampaignWizardState, RootState>,
    step: number
  ) => {
    context.commit('updateStep', step);
  },

  addTask: async (
    context: ActionContext<CampaignWizardState, RootState>,
    task: any
  ) => {
    context.commit('addTask', task);
  },
  initNewCampaign: async (
    context: ActionContext<CampaignWizardState, RootState>
  ) => {
    const { active } = context.state;
    const { step } = context.state;

    if (!active) {
      context.commit('setActivity', true);
      context.commit('updateLocations', []);
      context.commit('updateName', '');
      context.commit('updateDesignerEmail', '');
      context.commit('resetTime');
      context.commit('resetPayment');
      context.commit('resetMedia');
      return;
    }

    // Prevent going further than account step without auth
    // Or get back to step 4 if authenticated as payment step counts 4
    if (step > 4) {
      context.commit('updateStep', 4);
    }
  },
  editCampaign: async (
    { commit, dispatch }: ActionContext<CampaignWizardState, RootState>,
    {
      locations,
      dbDataObject,
      name,
      startDate,
      durationInWeeks,
      mediaList,
      designerEmail,
    }: AdvertiserCampaign
  ) => {
    const timeData: TimeSlotSelection = {
      frequency: defaultCampaignFrequency,
      duration: String(durationInWeeks),
      startDate: moment(startDate).format('YYYY-MM-DD'),
    };

    const mediaData = {
      files: mediaList,
      option: 'UPLOAD_NOW'
    };
    void dispatch('resetCampaign');
    commit('setActivity', true);
    commit('updateLocations', locations);
    commit('updateName', name);
    commit('updateDesignerEmail', designerEmail);
    commit('updateTime', timeData);
    commit('resetPayment');
    commit('updateMedia', mediaData);
    commit('setSavedCampaign', dbDataObject);
    commit('updateStep', 1);
  },
  resetCampaign: async ({
    commit,
  }: ActionContext<CampaignWizardState, RootState>) => {
    commit('setActivity', false);
    commit('updateStep', 1);
    commit('updateName', '');
    commit('resetTime');
    commit('resetPayment');
    commit('resetLocationPrices');
    commit('resetName');
    commit('resetDesignerEmail');
    commit('resetMedia');
    commit('setDiscounts', []);
    commit('setPromoCode', {});
    commit('setTotalPaymentPrice', 0);
    commit('setTotalDiscount', 0);
    commit('setPaymentMetaData', {});
    commit('setSavedCampaign', undefined);
  },
  saveCampaign: async (
    context: ActionContext<CampaignWizardState, RootState>
  ) => {
    const campaign = await saveCampaign(context.state);
    context.commit('setSavedCampaign', campaign);
    return campaign;
  },
  saveStoreOwnerCampaign: async (
    context: ActionContext<CampaignWizardState, RootState>
  ) => {
    return saveStoreOwnerCampaign(context.state);
  },
  checkAndUpdateAnonymousMedia: async (
    context: ActionContext<CampaignWizardState, RootState>
  ) => {
    const {
      state: { media, savedCampaign = {} as Campaign },
      rootGetters,
    } = context;
    const { ID: campaignId = '' } = savedCampaign as Campaign;
    const { UID: advertiserId = '' } = rootGetters[
      'AuthModule/userInfo'
    ] as AuthorizedUser;

    if (media?.files && media.files.length) {
      const promiseArray: any[] = [];
      media?.files.forEach((media: MediaFile) => {
        const { ref } = media;
        promiseArray.push(ref?.updateMetadata({
          customMetadata: {
            campaignId,
            advertiserId,
          },
        }));
      })

      await Promise.all(promiseArray);
    }
  },
  updateMyCampaign: async (
    { commit, dispatch }: ActionContext<CampaignWizardState, RootState>,
    campaignData: OnlyRequired<Campaign, 'ID'>
  ) => {
    return updateCampaign(campaignData);
  },
  updateBillingContact: async (
    { commit, dispatch }: ActionContext<CampaignWizardState, RootState>,
    billingContact: RecentBillingContact
  ) => {
    return updateRecentBillingContact(billingContact);
  },
  checkoutCampaign: async ({
    commit,
    state,
  }: ActionContext<CampaignWizardState, RootState>) => {
    try {
      const { promoCode = {}, savedCampaign } = state;
      const { ID: campaignId } = savedCampaign!;
      const { code } = promoCode!;

      const { checkoutId, paymentBill, returnUrl } = await checkoutCampaign(
        campaignId,
        code
      );

      const {
        DISCOUNTS_LIST: discountList,
        TOTAL_PRICE: totalPrice,
        TOTAL_DISCOUNT: totalDiscount,
      } = paymentBill;

      const updatedPromoCodeData = getUpdatedPromoCodeData(
        promoCode,
        paymentBill
      );

      commit('setDiscounts', discountList);
      commit('setPromoCode', {
        ...updatedPromoCodeData,
      });
      commit('setTotalPaymentPrice', totalPrice);
      commit('setTotalDiscount', totalDiscount);
      commit('setPaymentMetaData', {
        checkoutId,
        returnUrl,
      });
    } catch (error) {
      throw error;
    }
  },
};
