import { FirebaseAppAuth, FirebaseAppFirestore } from '@/firebase/firebase-app';
import { getCurrentUser } from '@/firebase/firebase-user';
import firebaseNames from '@/statics/firebase-names';
import { AuthorizedUser, UserInfo } from '@/types/users';
import { flatten } from 'lodash';
import {Campaign, PlaylistCampaign, StoreOwnerCampaign} from '@/types/campaigns';
import {AdSpace} from '@/types/adspaces';
import {isAdvertiserCampaign, isStoreOwnerCampaign} from "@/utils/campaigns";

/**
 * Get user info by user id
 * @param userId User ID
 */
export const getUserInfoById = async (
  userId: string
): Promise<AuthorizedUser> => {
  const userRef = FirebaseAppFirestore.collection(firebaseNames.USERS_INFO).doc(
    userId
  );
  const userSnap = await userRef.get();

  if (userSnap.exists) {
    return userSnap.data() as AuthorizedUser;
  }

  throw new Error('USER_INFO_NOT_FOUND');
};

/**
 * Create related information for a user.
 * @param userInfo User information
 */
export const createUserInfo = async (userInfo: AuthorizedUser) => {
  await FirebaseAppFirestore.collection(firebaseNames.USERS_INFO)
    .doc(userInfo.UID)
    .set(userInfo);
};

/**
 * Get user info by mobile number
 * @param mobileNumber User mobile number
 */
export const getUserInfoByMobileNumber = async (
  mobileNumber: string
): Promise<AuthorizedUser|null> => {
  if (!FirebaseAppAuth.currentUser) {
    await FirebaseAppAuth.signInAnonymously();
  }

  const userSnap = await FirebaseAppFirestore.collection(
    firebaseNames.USERS_INFO
  )
    .where('PHONE_NUMBER', '==', mobileNumber)
    .get();

  if (!userSnap.empty) {
    const [user] = userSnap.docs.map((doc) => doc.data() as AuthorizedUser);
    return user;
  }

  return null;
};

/**
 * Get user info by vat number
 * @param vatNumber User vat number
 */
 export const getUserInfoByVatNumber = async (
  vatNumber: string
): Promise<AuthorizedUser|null> => {
  if (!FirebaseAppAuth.currentUser) {
    await FirebaseAppAuth.signInAnonymously();
  }

  const userSnap = await FirebaseAppFirestore.collection(
    firebaseNames.USERS_INFO
  )
    .where('VAT_NUMBER', '==', vatNumber)
    .get();

  if (!userSnap.empty) {
    const [user] = userSnap.docs.map((doc) => doc.data() as AuthorizedUser);
    return user;
  }

  return null;
};

/**
 * Gets user by UID.
 * @param uid User ID
 */
 export const getUserById = async (uid: string): Promise<AuthorizedUser | undefined> => {
  const dataRef = await FirebaseAppFirestore
    .collection(firebaseNames.USERS_INFO)
    .doc(uid)
    .get();
  const dataDoc = dataRef.data();
  return dataDoc as AuthorizedUser;
 };


export const getUserInfoAction = async (): Promise<UserInfo> => {
  const currentUser = await getCurrentUser();
  if (!currentUser) {
    throw new Error('User is not authenticated');
  }

  const docRef = await FirebaseAppFirestore
    .collection(firebaseNames.USERS_INFO)
    .doc(currentUser!.uid)
    .get();

  return docRef.data() as UserInfo;
};

export const signInAnonymously = async (): Promise<void> => {
  if (!FirebaseAppAuth.currentUser) {
    await FirebaseAppAuth.signInAnonymously();
  }
}

export const getUsersInfoByIDs = async (UIDs: string[]): Promise<AuthorizedUser[]> => {
  let batches = [];

  while (UIDs.length) {
    // firestore limits batches to 10
    const batch = UIDs.splice(0, 10);

    batches.push(
      FirebaseAppFirestore
        .collection(firebaseNames.USERS_INFO)
        .where(
          'UID',
          'in',
          [...batch]
        )
        .get()
    )
  }

  const snap = await Promise.all(batches);

  return flatten(snap.map(data => data.docs.map(doc => doc.data() as AuthorizedUser)))
}

export const getUserByCompanyName = async (companyName: string) => {
  const snap = await FirebaseAppFirestore
    .collection(firebaseNames.USERS_INFO)
    .where('COMPANY_NAME', '==', companyName)
    .get();
  return snap.empty;
};

export const getAdvertiserCompanyName = async (campaigns: PlaylistCampaign[]): Promise<Record<string, string>> => {
  const advertiserIdList: Array<Campaign['ADVERTISER_UID']> = campaigns
    .filter(isAdvertiserCampaign)
    .reduce((advertiserIds,campaign)=>([
    ...advertiserIds,
      (campaign as unknown as Campaign).ADVERTISER_UID as Campaign['ADVERTISER_UID'],
  ]),[] as Array<Campaign['ADVERTISER_UID']>);
  const users = await getUsersInfoByIDs(advertiserIdList);
  return users.reduce((usersCompaniesMap, user) => ({
    ...usersCompaniesMap,
    [user.UID]: user.COMPANY_NAME,
  }), {});
};

export const getStoreOwnerCompanyName = async (storeOwnerUid: AuthorizedUser['UID']): Promise<AuthorizedUser['COMPANY_NAME']> => {
  const storeOwnerUser = await getUserInfoById(storeOwnerUid);
  return storeOwnerUser.COMPANY_NAME
}

export const getStoreOwnersCompanyName = async (campaigns: PlaylistCampaign[]): Promise<Record<AuthorizedUser['UID'], AuthorizedUser['COMPANY_NAME']>> => {
  const usersMap: StoreOwnerCampaign['STORE_OWNER_UID'][] = campaigns
    .filter(isStoreOwnerCampaign)
    .reduce((storeOwnerIds,campaign)=>([
      ...storeOwnerIds,
      (campaign as unknown as StoreOwnerCampaign).STORE_OWNER_UID as StoreOwnerCampaign['STORE_OWNER_UID'],
    ]),[] as StoreOwnerCampaign['STORE_OWNER_UID'][]);
  const storeOwnerIdList = Object.values(usersMap);
  const users = await getUsersInfoByIDs(storeOwnerIdList);
  return users.reduce((usersCompaniesMap, user) => ({
    ...usersCompaniesMap,
    [user.UID]: user.COMPANY_NAME,
  }), {});
}
