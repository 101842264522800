
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import BigCard from '@/ui-components/BigCard/BigCard.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import CampaignStatus from '@/ui-components/CampaignStatus/CampaignStatus.vue';
import TableRow from './components/TableRow.vue';
import Pagination from './components/Pagination.vue';
import ItemsRange from './components/ItemsRange.vue';
import { StoreCampaign } from '@/types/campaigns';
import { Header } from '@/types/misc';
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { SetSelectedPage } from '@/store/modules/incoming-campaigns-module/types';

@Component({
  name: 'CampaignsTable',
  components: {
    BigCard,
    CampaignStatus,
    IButton,
    TableRow,
    Pagination,
    ItemsRange,
  },
})
export default class CampaignsTable extends Vue {
  @Prop({ type: Array, default: () => [] })
  public campaigns!: StoreCampaign[];

  @Prop({ type: Number, default: 10 })
  public itemsPerPage!: number;

  @Prop({ type: String })
  public hint!: string;

  @Prop({ type: Boolean })
  public showTotal!: boolean;

  @Prop({ type: Boolean })
  public showPagination!: boolean;

  @Prop({ type: Array, default: () => [] })
  public headers!: Header[];

  @Prop({ type: String })
  public activeTab!: string;

  @Action('setSelectedPage', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public setSelectedPage!: SetSelectedPage;

  @Getter('selectedPage', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public storeSelectedPage!: number;

  public selectedPage = 1;


  public goToPage(page: number) {
    if (page < 1 || page > this.totalPagesCount) {
      return;
    }
    this.selectedPage = page;
  }

  public get totalPagesCount() {
    return Math.round(this.totalCount / this.itemsPerPage);
  }

  public get totalCount() {
    return this.campaigns.length;
  }
  public get footerProps(){
    return {'items-per-page-all-text':this.$t('all'), 'items-per-page-text':this.$t('rows_per_page'),}
  }
  public get displayedPaginationPages() {
    const possiblePages = [
      this.selectedPage - 2,
      this.selectedPage - 1,
      this.selectedPage,
      this.selectedPage + 1,
      this.selectedPage + 2,
    ];
    const filteredPossibilities = possiblePages.filter(
      (item) => item > 1 && item < this.totalPagesCount
    );
    const minimumPage = this.selectedPage <= 3 ? 0 : 1;
    const maximumPage = minimumPage + 2;

    return filteredPossibilities.length > 2
      ? filteredPossibilities.splice(minimumPage, maximumPage)
      : filteredPossibilities;
  }

  public get firstItemCount() {
    return (
      (this.selectedPage ? this.selectedPage - 1 : this.selectedPage) *
        this.itemsPerPage +
      1
    );
  }

  public get lastItemCount() {
    return this.firstItemCount + this.itemsPerPage - 1;
  }

  public get paginationRange() {
    return `${this.firstItemCount} - ${this.lastItemCount} ${this.$t('_of')} ${
      this.totalCount
    } ${this.$t('_items')}`;
  }

  public customSort(storeCampaign: StoreCampaign[], sortItems: string[] = [], sortTypes: boolean[],locale: string) {
    const [ index ] = sortItems;
    const [ isDesc ] = sortTypes;
    storeCampaign.sort((a: StoreCampaign, b: StoreCampaign) => {
      let leftValue: any;
      let rightValue: any;
  
      const getDurationMultiplier = (key: string) => {
        return (key === 'weeks' || key === 'week') ? 7 : 1
      }
      const aDurationInWeeks = a.durationInWeeks;
      const bDurationInWeeks = b.durationInWeeks;

      const aRemainingDuration = a.remainingDuration;
      const bRemainingDuration = b.remainingDuration;
      switch (index) {
        case 'startDate': {
          leftValue = new Date(a[index]).valueOf();
          rightValue = new Date(b[index]).valueOf();
          break;
        }
          case 'endDate':{
            leftValue = new Date(a[index]).valueOf();
            rightValue = new Date(b[index]).valueOf();
            break;
          }
          case 'status':{
            leftValue = a.status.VAL;
            rightValue = b.status.VAL;
            break;
          }
        case 'durationInWeeks': {
          leftValue = aDurationInWeeks.number * getDurationMultiplier(aDurationInWeeks.key);
          rightValue = bDurationInWeeks.number * getDurationMultiplier(bDurationInWeeks.key);
          break;
        }
        case 'remainingDuration': {
          leftValue = aRemainingDuration.number * getDurationMultiplier(aRemainingDuration.key);
          rightValue = bRemainingDuration.number * getDurationMultiplier(bRemainingDuration.key);
          break;
        }
          default:{
            leftValue = a[index];
            rightValue = b[index];
            break;
          }
      }

      if (isDesc) {
        return rightValue > leftValue ? 1 : -1;
      } else {
        return rightValue > leftValue ? -1 : 1;
      }
    });
    return storeCampaign;
  }

  @Emit('rowClick')
  public rowClick(campaign: StoreCampaign) {
    return campaign;
  }

  created() {
    this.selectedPage = this.storeSelectedPage;
  }

  @Watch('selectedPage')
  selectedPageChanged(page: number) {
    this.setSelectedPage(page);
  }

  @Watch('activeTab')
  campaignTypeChanged() {
    this.selectedPage = 1;
    this.setSelectedPage(1);

  }
}
