
import { Component, Prop, Vue } from 'vue-property-decorator';
import CampaignsTable from '@/shared-components/CampaignsTable/CampaignsTable.vue';
import { CompletedCampaign, StoreCampaign } from '@/types/campaigns';
import { storeOwnerRouteNames } from '@/route-names/store-owner';
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { SetStoreCampaigns } from '@/store/modules/store-module/types';

@Component({
  name: 'CompletedCampaignsTable',
  components: {
    CampaignsTable,
  },
})
export default class CompletedCampaignsTable extends Vue {
  @Prop({ type: Array, default: () => [] })
  public campaigns!: CompletedCampaign[];

  @Prop({ type: String })
  public activeTab!: string;

  @Action('setStoreCampaigns', {
    namespace: namespaces.StoreModule,
  })
  public setStoreCampaigns!: SetStoreCampaigns;

  public itemsPerPage = 10;

  public get headers (){
    return [
    {
      text: this.$t('advertiser'),
      value: 'advertiserCompanyName',
    },
    {
      text: this.$t('start_date'),
      value: 'startDate',
    },
    {
      text: this.$t('end_date'),
      value: 'endDate',
    },
    {
      text: this.$t('_status'),
      value: 'status',
    },
    {
      text: this.$t('branches'),
      value: 'branchesNames',
    },
    {
      text: this.$t('total_duration'),
      value: 'durationInWeeks',
    },
    {
      text: this.$t('total_expected_revenue'),
      value: 'expectedTotalEarning',
    },
  ];
  }

  public openCampaignViewPage({ campaignRequest: { ID: id } }: StoreCampaign) {
    this.setStoreCampaigns(this.campaigns);
    this.$router.push({
      ...storeOwnerRouteNames.INCOMING_CAMPAIGN_VIEW,
      params: {
        id
      }
    })
  }
}
