
import { Component, Prop, Vue } from 'vue-property-decorator';
import BigCard from '@/ui-components/BigCard/BigCard.vue';
import InfoCircle from '@/ui-components/InfoCircle/InfoCircle.vue';
import CampaignReviewDialog from '@/dialogs/store-owner/CampaignReviewDialog.vue';
import MediaChangeReviewDialog from '@/dialogs/store-owner/MediaChangeReviewDialog.vue';
import Media from '@/ui-components/Media/Media.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import { PendingCampaign } from '@/types/campaigns';
import moment from 'moment';
import { dateMinimizer, getMomentLocaleKey } from "@/utils/date";
import { clockifyNumber } from '@/utils/numbers';
import { SYSTEM_STATUS } from '@/statics/system-status';
import { Dialog } from '@/types/misc';
import Truncated from '@/shared-components/Truncated.vue';

@Component({
  components: {
    Truncated,
    BigCard,
    InfoCircle,
    Media,
    IButton,
    CampaignReviewDialog,
    MediaChangeReviewDialog,
  },
  name: 'PendingCampaignCard',
})
export default class PendingCampaignCard extends Vue {
  @Prop({ required: true, type: Object }) campaign!: PendingCampaign;
  @Prop({ type: String }) description!: string;

  public timer: any = null;
  public now = new Date();

  public get campaignRelativeRequestDate() {
    const receiveDate = moment(this.dateToCompare);
    return dateMinimizer(moment.min(receiveDate, moment()).locale(getMomentLocaleKey()).fromNow());
  }

  public get dateToCompare() {
    return this.campaign.approvedAt;
  }

  public get campaignMedia() {
    if (this.campaign.mediaList && this.campaign.mediaList.length) {
      const [media] = this.campaign.mediaList;
      return media;
    }
    return this.campaign.media;
  }

  public get timeRemaining() {
    const now = moment(this.now);
    const recieveDate = moment(this.dateToCompare);
    const diff = moment.duration(recieveDate.add(1, 'day').diff(now));

    return [diff.hours(), diff.minutes(), diff.seconds()].map(clockifyNumber).join(':');
  }

  public get isChangeMediaRequest() {
    return (
      this.campaign.status.VAL === SYSTEM_STATUS.RUNNING.VAL &&
      this.campaign.mediaList.some((media) => media.changeRequestMedia)
    );
  }

  public openCampaignReviewDialog() {
    const dialogName = this.isChangeMediaRequest ? 'mediaChangeReviewDialogRef' : 'campaignReviewDialogRef';
    const dialog = this.$refs[dialogName] as unknown as Dialog;
    dialog.open();
  }
  get durationInWeeks(){
    return this.campaign.durationInWeeks.number;
  }
  public mounted() {
    this.timer = setInterval(() => {
      this.now = new Date();
    }, 1000);
  }

  public beforeDestroy() {
    clearInterval(this.timer);
  }
}
