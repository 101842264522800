
  import {Component, Vue} from 'vue-property-decorator';
  import IButton from '@/ui-components/IButton/IButton.vue';
  import IDialog from '@/ui-components/IDialog/IDialog.vue';
  import TextField from '@/ui-components/TextField/TextField.vue';

  import {rules} from '@/validation-rules';
  import {StoreCampaign} from '@/types/campaigns';
  import {Action} from 'vuex-class';
  import namespaces from '@/store/namespaces';
  import {GetStoreCampaignById} from '@/store/modules/store-module/types';
  import EditCampaignTitle from './components/EditCampaignTitle.vue';
  import {MediaFile, MediaResolution} from '@/types/media';
  import Upload from '@/shared-components/Upload.vue';
  import {cloneDeep} from 'lodash';
  import DatePickerInput from '@/ui-components/DatePickerInput/DatePickerInput.vue';
  import TabsItems from '@/ui-components/Tabs/TabsItems.vue';
  import TabItem from '@/ui-components/Tabs/TabItem.vue';
  import moment from 'moment';
  import CheckDurationDialog from './components/CheckDurationDialog.vue';
  import PublishConfirmationDialog from './components/PublishConfirmationDialog.vue';
  import {getAbbreviatedUserType, updateStoreOwnerCampaign} from '@/actions/campaigns/actions';
  import firebase from 'firebase';
  import {ResetAllCampaigns} from '@/store/modules/incoming-campaigns-module/types';
  import {storeOwnerRouteNames} from '@/route-names/store-owner';
  import { mapMediaToLocations, mapCampaignMediaToMediaFile, mediaNameFactory } from '@/utils/media';
  import { getUserInfoAction } from '@/actions/users/actions';
  import {SYSTEM_STATUS} from "@/statics/system-status";


  @Component({
    name: 'EditViewPage',
    components: {
      IButton,
      IDialog,
      TextField,
      EditCampaignTitle,
      Upload,
      DatePickerInput,
      TabsItems,
      TabItem,
      CheckDurationDialog,
      PublishConfirmationDialog,
    },
  })
  export default class CampaignViewPage extends Vue {

    @Action('getStoreCampaignById', {
      namespace: namespaces.StoreModule,
    })
    public getStoreCampaignById!: GetStoreCampaignById;
    @Action('resetAllCampaigns', {
      namespace: namespaces.IncomingCampaignsModule,
    })
    public resetAllCampaigns!: ResetAllCampaigns;

    public campaign: StoreCampaign | null = null;
    valid = false;
    loading = false;
    advertiserName = '';
    selectedMediaFiles: MediaFile[] = [];
    public acceptedFileTypes = ['jpg', 'jpeg', 'mp4'];
    public TODAY = moment().format('YYYY-MM-DD');
    public RUNNING = SYSTEM_STATUS.RUNNING;
    public requiredRule = rules.required('This field is required');
    activeTabIndex = 0;
    public duration: string | null = null;
    public startDate = '';
    public endDate = '';
    public isFormChanged = false;
    public durationRules = [
      rules.onlyPositiveNumberOptional(this.$t('only_positive_number_allowed').toString()),
      rules.maxLengthOptional(
        2,
        this.$t('max_length', {
          field: this.$t('duration_small').toString(),
          maxLength: 99,
        }).toString()
      ),
    ];
    public startDateRules = [this.requiredRule];

    public calendarIcon = '/assets/icons/calendar.svg';

    public get advertiserNameRules() {
      return [
        rules.required(this.$i18n.t('please_enter_your_advertiser_name') as string),
        rules.maxLength(30, this.$t('max_length', {
          field: this.$t('advertiser_small').toString(),
          maxLength: 30
        }).toString()),
      ];
    }

    get minEndDate() {
      return moment(this.startDate || this.TODAY).add('day', 1).format('YYYY-MM-DD')
    }

    public get campaignId() {
      return this.$route.params.id;
    }

    get campaignHasEndDateOrDuration() {
      return this.activeTabIndex === 0 ? this.duration : this.endDate
    }

    get allMediaUploaded() {

      return this.selectedMediaFiles.every(mediaFile => mediaFile.path)
    }

    back() {
      return this.$router.go(-1);
    }

    fillFormInitialData() {
      this.advertiserName = this.campaign!.advertiserCompanyName || '';
      this.selectedMediaFiles = cloneDeep(this.campaign!.mediaList || []);
      this.startDate = moment(this.campaign!.startDate).format('YYYY-MM-DD');
      this.duration = String(this.campaign!.dbDurationInWeeks || '') || '';


      this.endDate = !this.campaign!.dbDurationInWeeks && moment(this.campaign!.endDate).format('YYYY-MM-DD').toString() || '';
      this.activeTabIndex = this.campaign!.dbDurationInWeeks ? 0 : 1;
    }

    public async getCampaignData() {

      this.campaign = await this.getStoreCampaignById(this.campaignId);
      if (!this.campaign || !this.campaignId) {
        this.$router.go(-1);
      } else {
        this.fillFormInitialData();
      }

    }

    private handleDurationChange(newValue: string) {
      if (newValue) {
        this.openCheckDurationDialog();
        this.endDate = '';
      }
    }

    created() {
      this.getCampaignData();
    }

    public acceptedMediaResolution({
                                     width = 0,
                                     height = 0,
                                     unit = 'px',
                                   }: MediaFile): MediaResolution {
      return {width, height, unit};
    }

    async handleMediaFileChanged(change: MediaFile | null, index: number) {
      const {ROLES, COMPANY_NAME} = await getUserInfoAction();
      const abbreviatedUserType = getAbbreviatedUserType(ROLES);
      const name = mediaNameFactory({
        mediaForLocation: change!,
        campaignName: this.campaign!.name,
        abbreviatedUserType: abbreviatedUserType,
        companyName: COMPANY_NAME,
      });
      Vue.set(this.selectedMediaFiles, index, {...change, name});

    }

    async saveChanges() {
      const updatedFields = this.dataToUpdate();
      const isTodaySelected = updatedFields.startDate
        && moment(updatedFields.startDate?.toDate()).format('YYYY-MM-DD') === this.TODAY;
      const isRunningCampaignHasMediaChanged = this.campaign!.status.VAL === SYSTEM_STATUS.RUNNING.VAL
        && Boolean(updatedFields.mediaList);
      const campaignConnectedToNavoriPlayer = this.campaign?.isConnectedToNavori;
      if (campaignConnectedToNavoriPlayer && (isTodaySelected || isRunningCampaignHasMediaChanged)) {
        this.openPublishConfirmationDialog()
      } else {
        this.saveAction();
      }
    }

    async saveAction() {
      try {
        this.loading = true;
        const updateFields = this.dataToUpdate();
        await updateStoreOwnerCampaign({...updateFields, id: this.campaignId});
        this.resetAllCampaigns();
        this.$router.replace({
          name: storeOwnerRouteNames.INCOMING_CAMPAIGNS.name,
        });
      } finally {
        this.loading = false;
      }
    }

    handleDurationBlur() {
      const isDurationNotEqualsCampaignDuration = Number(this.duration) !== Number(this.campaign!.dbDurationInWeeks)
      if (isDurationNotEqualsCampaignDuration)
        this.handleDurationChange(this.duration || '');
    }

    openCheckDurationDialog() {
      (this.$refs.checkDurationDialog as CheckDurationDialog).openDialog();
    }

    openPublishConfirmationDialog() {
      (this.$refs.publishConfirmationDialog as PublishConfirmationDialog).openDialog();
    }

    onStartDateChanged(date: string) {
      if (this.endDate && new Date(this.endDate).getTime() <= new Date(date).getTime()) {
        this.endDate = '';
      }
      this.openCheckDurationDialog();
    }

    onEndDateChanged() {
      this.openCheckDurationDialog();
      this.duration = null;
    }

    dataToUpdate() {
      const mediaMap = this.campaign!.mediaList?.reduce((acc, mediaFile) => ({
        ...acc,
        [mediaFile.path as string]: mediaFile,
      }), {} as Record<string, MediaFile>);

      const isMediaChanged = this.selectedMediaFiles.some((mediaFile) => !mediaMap[mediaFile.path as string]);
      const campaignHasDuration = this.campaign!.dbDurationInWeeks
      const isEndDateChanged = this.activeTabIndex === 1 &&
        (this.endDate !== moment(this.campaign!.endDate).format('YYYY-MM-DD') || campaignHasDuration)

      const updatedFields = {
        ...(this.advertiserName.trim() !== this.campaign!.advertiserCompanyName && {advertiserName: this.advertiserName.trim()}),
        ...(isMediaChanged && {
          mediaList: mapMediaToLocations({
            mediaItems: this.selectedMediaFiles,
            locations: this.campaign!.locations
          }).map(campaignMedia => mapCampaignMediaToMediaFile(campaignMedia))
        }),
        ...(this.startDate !== moment(this.campaign!.startDate).format('YYYY-MM-DD') && {
          startDate: firebase.firestore.Timestamp.fromDate(moment(new Date(this.startDate)).toDate())
        }),
        ...(isEndDateChanged &&
          {
            endDate: firebase.firestore.Timestamp.fromDate(moment(new Date(this.endDate)).toDate()),
            deleteDurationInWeeks: true
          }),
        ...(this.activeTabIndex === 0 && Number(this.duration) !== Number(this.campaign!.dbDurationInWeeks) && {durationInWeeks: Number(this.duration)}),
      };
      this.isFormChanged = Boolean(Object.keys(updatedFields).length);
      return updatedFields;
    }

    updated() {
      if (this.campaign) {
        this.dataToUpdate();
      }
    }
  }
