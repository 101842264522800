
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { LocationData } from "@/types/locations";
import MediaCarousel from "@/ui-components/MediaCarousel/MediaCarousel.vue";
import IButton from "@/ui-components/IButton/IButton.vue";
import Media from "@/ui-components/Media/Media.vue";
import IDialog from "@/ui-components/IDialog/IDialog.vue";
import { defaultLocationCardCampaignFrequency } from '@/statics/campaign-wizard';

@Component({
  name: "LocationDetails",
  components: {
    MediaCarousel,
    IButton,
    Media,
    IDialog
  }
})
export default class LocationDetails extends Vue {
  @Prop() location!: LocationData;
  @Prop() selected!: boolean;
  @Prop({ type: Boolean, default: false }) openLocationDetails!: boolean;

  public defaultFrequency = defaultLocationCardCampaignFrequency;

  get resolution(): string {
    const { SCREENS_RESOLUTION: { WIDTH, HEIGHT, UNIT } = {} } = this.location;

    return `${WIDTH}x${HEIGHT} ${UNIT}`;
  }

  get mediaList() {
    return this.location.mediaList;
  }

  get media() {
    return this.location.media;
  }

  get screenLabel() {
    return this.location?.SCREENS_COUNT > 1 ? this.$t("screens") : this.$t("screen");
  }

  handleSelection() {
    if (this.selected) {
      return this.deselect();
    }

    if (this.openLocationDetails) {
      this.toggleLocationDetails();
    }

    this.select();
  }

  @Emit("update:openLocationDetails")
  toggleLocationDetails() {
    return !this.openLocationDetails;
  }

  @Emit()
  select() {
    return this.location;
  }

  @Emit()
  deselect() {
    return this.location;
  }
}
