
import { Component, Prop, Vue } from 'vue-property-decorator';
import Card from '@/ui-components/Card/Card.vue';
import Media from '@/ui-components/Media/Media.vue';
import { AdvertiserCampaign } from '@/types/campaigns';
import CampaignData from './components/CampaignData.vue';
import VerticalDivider from '../VerticalDivider/VerticalDivider.vue';
import { SYSTEM_STATUS } from '@/statics/system-status';

@Component({
  components: {
    Card,
    CampaignData,
    Media,
    VerticalDivider,
  },
  name: 'MyCampaignCard',
})
export default class MyCampaignCard extends Vue {
  @Prop({ required: true, type: Object }) campaign!: AdvertiserCampaign;

  public get media() {
    if (this.campaign.mediaList && this.campaign.mediaList.length) {
      const [ media ] = this.campaign.mediaList;
      return media;
    }
    return this.campaign.media;
  }

  public get isRejectedCampaign(): boolean {
    return this.campaign.status.VAL === SYSTEM_STATUS.REQUIRES_ACTION.VAL
      || this.campaign.dbDataObject.MEDIA_CHANGE_REQUEST_STATUS?.VAL === SYSTEM_STATUS.REJECTED.VAL;
  }
}
