import { render, staticRenderFns } from "./PublishConfirmationDialog.vue?vue&type=template&id=0262dd8b&scoped=true"
import script from "./PublishConfirmationDialog.vue?vue&type=script&lang=ts"
export * from "./PublishConfirmationDialog.vue?vue&type=script&lang=ts"
import style0 from "./PublishConfirmationDialog.vue?vue&type=style&index=0&id=0262dd8b&prod&lang=sass&scoped=true"
import style1 from "./PublishConfirmationDialog.vue?vue&type=style&index=1&id=0262dd8b&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0262dd8b",
  null
  
)

export default component.exports