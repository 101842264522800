
import { Component, Prop, Vue } from 'vue-property-decorator';
import Media from '@/ui-components/Media/Media.vue';
import { PlaylistCampaign } from '@/types/campaigns';
import BigCard from '@/ui-components/BigCard/BigCard.vue';

@Component({
  name: 'PlaylistTable',
  components: { Media, BigCard },
})
export default class PlaylistTable extends Vue {
  @Prop({ required: true }) campaignList!: PlaylistCampaign[];
  @Prop({ required: true }) generatePlaylistView!: boolean;
  itemsPerPage = -1;
  firstItemId = this.campaignList[0].ID;

  public get headers (){
    return [
    { text: this.$t('media'), sortable: false, class: this.generatePlaylistView ? 'd-none' : '' },
    { text: this.$t('advertiser'), sortable: false },
    { text: this.$t('frequency'), sortable: false },
    { text: this.$t('start_date'), sortable: false },
    { text: this.$t('end_date'), sortable: false },
  ];
  }
}
