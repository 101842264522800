export const theme = {
  dark: false,
  themes: {
    light: {
      primary: '#92298D',
      secondary: '#213F7D',
      danger: '#EF392E',
      accent: '#92298D',
    },
    dark: {
      primary: '#92298D',
      secondary: '#213F7D',
      danger: '#EF392E',
      accent: '#92298D',
    },
  },
};
