
import { Component, Prop, Vue } from 'vue-property-decorator';
import TabItem from '@/ui-components/Tabs/TabItem.vue';
import SkeletonLoading from './SkeletonLoading.vue';

import NoCampaigns from '@/shared-components/NoCampaigns.vue';
import { CategorizedStorePendingCampaignsList } from '@/types/campaigns';
import UpcomingCampaignsTable
  from '@/pages/regulator/MyCampaigns/components/UpcomingCampaigns/UpcomingCampaignsTable.vue';

@Component({
  name: 'UpcomingCampaigns',
  components: {
    TabItem,
    NoCampaigns,
    SkeletonLoading,
    UpcomingCampaignsTable
  },
})
export default class UpcomingCampaigns extends Vue {
  @Prop({ type: Array, default: () => [] })
  public campaigns!: CategorizedStorePendingCampaignsList;

  @Prop({ type: Boolean })
  public loading!: boolean;
  @Prop({ type: String })
  public activeTab!: string;

}
