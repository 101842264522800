
import { Component, Vue } from 'vue-property-decorator';
import AppBarEnd from './components/AppBarEnd.vue';
import AppBar from '@/ui-components/AppBar/AppBar.vue';
import Logo from '@/ui-components/Logo/Logo.vue';
import AppBarStart from '@/layouts/components/AppBarStart.vue';
import NavButtons from "@/ui-components/NavButtons/NavButtons.vue";
import namespaces from '@/store/namespaces';
import { Getter } from 'vuex-class';
import { initializeLanguageAndDirection } from "@/utils/locale";
import {sharedNavButtons} from "@/statics/nav-buttons";

@Component({
  name: 'SharedLayout',
  components: {
    AppBarStart,
    AppBar,
    AppBarEnd,
    Logo,
    NavButtons,
  },
})
export default class SharedLayout extends Vue {
  @Getter('isAuthenticated', { namespace: namespaces.AuthModule })
  public isAuthenticated!: boolean;
  isHeaderVisible = true;
  sharedNavButtons = sharedNavButtons;

  public navigateToLanding() {
    this.$router.push('/');
  }
  created(){
    initializeLanguageAndDirection()
  }
}
