
import { Component, Prop, Vue } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';
import { StoreCampaign } from '@/types/campaigns';

@Component({
  name: 'EditCampaignTitle',
  components: {
    IButton,
  },
})
export default class EditCampaignTitle extends Vue {
  @Prop({ type: Object }) public campaign!: StoreCampaign;

  public get campaignName() {
    return this.campaign?.advertiserCompanyName;
  }

  public back() {
    return this.$router.go(-1);
  }
}
