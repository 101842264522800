import { regulatorRouteNames } from '@/route-names/regulator';
import RegulatorLayout from '@/layouts/RegulatorLayout/RegulatorLayout.vue';
const MyCampaignsPage = () => import('@/pages/regulator/MyCampaigns/MyCampaigns.vue');
const PlaylistCampaignsPage = () => import('@/pages/regulator/Playlist/Playlist.vue');
const CampaignViewPage = () => import('@/pages/store-owner/CampaignView/CampaignView.vue');
export const regulatorRoutes = [
  {
    path: '/regulator',
    component: RegulatorLayout,
    meta: {
      requiresAuth: true,
      routeRole: 'regulator'
    },
    children: [
      {
        ...regulatorRouteNames.MY_CAMPAIGNS,
        path: 'my-campaigns',
        component : MyCampaignsPage,
        children: [],
      },{
        ...regulatorRouteNames.PLAYLIST,
        path: 'playlist',
        component : PlaylistCampaignsPage,
        children: [],
      },
      {
        ...regulatorRouteNames.MY_CAMPAIGN_DETAILS,
        path: 'my-campaigns/campaign/:id',
        component: CampaignViewPage,
        children: [],
      },
    ]
  },
];
