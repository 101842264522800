import { typeFromMime } from '@/utils/files';
import {
  UpdatedCampaignPropertiesWhenMediaChanged,
  ClustersSchedulesRecord,
  AdvertiserCampaign,
  StoreOwnerIncomingCampaign,
  StoreOwnerCampaign,
  PlaylistCampaign,
  ShashaAd,
} from '@/types/campaigns';
import { LocationData, LocationPrice } from '@/types/locations';
import { StoreCampaign } from '@/types/campaigns';
import { CampaignMedia, MediaFile } from '@/types/media';
import i18n from '@/plugins/i18n';
import { SYSTEM_STATUS } from '@/statics/system-status';
import {
  Campaign,
  CampaignClustersDiscountsRecord,
  CampaignClustersPricesRecord,
} from '@/types/campaigns';
import { PaymentBill } from '@/types/payment';
import { ScreenCluster } from '@/types/screen-cluster';
import { AuthorizedUser } from '@/types/users';
import { flatten, sum, flow, size, reduce } from 'lodash';
import mapValues from 'lodash/mapValues'
import values from 'lodash/values';
import { filterRecordByKeys, isNonNullable } from './misc';
import { Schedule } from '@/types/schedules';
import moment from 'moment';
import { getDateFromServerTimeStamp } from './date';
import { mapCampaignMediaToMediaFile } from './media';
import { SystemStatus } from '@/types/misc';
import firebase from 'firebase';
import firestore = firebase.firestore;
import { SerializedFirebaseTimestamp } from '@/types/date';
import { AdSpace } from '@/types/adspaces';
import { pluralizeDays, pluralizeWeeks } from '@/utils/text';


/**
 * Filter the data of a store campaign to only show:
 * - The total price of the campaign's schedules in this store's locations
 * - The total discount applied to the campaign's schedules in this store's locations
 * - The screen clusters selected in the campaign that belong to the store's locations
 * @param campaignData
 * @param storeScreenClusters
 * @param locationsList
 */
export const filterStoreCampaignData = (
  campaignData: StoreOwnerIncomingCampaign,
  storeScreenClusters: ScreenCluster[],
  locationsList: LocationData[]
): Campaign => {
  const [mappedCampaign] = [campaignData]
    .map((campaign) => filterStoreOwnerRevenueFromCampaign(campaign, storeScreenClusters))
    .map((campaign) =>
      filterStoreMediaFromCampaign(campaign, storeScreenClusters, locationsList)
    )
    .map((campaign) =>
      filterCampaignClusterSchedules(campaign, storeScreenClusters)
    )
    .map((campaign) => filterCampaignClusters(campaign, storeScreenClusters));
  return mappedCampaign;
};

const calculateCampaignPriceByPriceRecords = (
  pricesRecord: CampaignClustersPricesRecord,
  discountsRecord: CampaignClustersDiscountsRecord
): Pick<PaymentBill, 'TOTAL_PRICE' | 'TOTAL_DISCOUNT' | 'DISCOUNTS_LIST'> => {
  const subTotal = Object.values(pricesRecord).reduce(
    (total, campaignClusterPrice) => total + campaignClusterPrice,
    0
  );

  const DISCOUNTS_LIST = Object.values(discountsRecord).filter(isNonNullable);

  const TOTAL_DISCOUNT = DISCOUNTS_LIST.map((discount) =>
    discount ? discount.discountValue : 0
  ).reduce((total, campaignDiscount) => total + campaignDiscount, 0);

  const TOTAL_PRICE = subTotal - TOTAL_DISCOUNT;

  return { TOTAL_PRICE, TOTAL_DISCOUNT, DISCOUNTS_LIST };
};

const getCampaignRevenueByLocationPrice = (campaign: StoreOwnerIncomingCampaign,
                                           storeScreenClusters: ScreenCluster[]): StoreOwnerIncomingCampaign => {
  const screenClustersIds = storeScreenClusters.map(({ ID }) => ID);
  const locationPricesRecord = filterRecordByKeys(
    mapValues(campaign.LOCATION_PRICES, (locationPrice: LocationPrice) =>
      locationPrice.BASE_PRICE),
    screenClustersIds
  );
  return {
    ...campaign,
    storeRevenue: {
      storePricesRecord: locationPricesRecord
    },
  };
}

export const filterStoreOwnerRevenueFromCampaign = (
  campaign: StoreOwnerIncomingCampaign,
  storeScreenClusters: ScreenCluster[]
): StoreOwnerIncomingCampaign => {
  if (campaign.LOCATION_PRICES) {
    return getCampaignRevenueByLocationPrice(campaign, storeScreenClusters)
  }
  if (!campaign.PAYMENT_BILL) {
    return campaign;
  }

  const {
    CLUSTERS_PRICES_RECORD: clustersPricesRecord = {},
    SELECTED_CLUSTERS_SUBTOTAL_PRICES_RECORD: clustersSubtotalPricesRecord = {},
    SELECTED_CLUSTERS_TOTAL_PRICES_RECORD: clustersTotalPricesRecord = {},
    CLUSTERS_DISCOUNTS_RECORD: clustersDiscountsRecord = {},
  } = campaign.PAYMENT_BILL;

  const storeScreenClustersIds = storeScreenClusters.map(({ ID }) => ID);

  const storeSubtotalPricesRecord = filterRecordByKeys(
    clustersSubtotalPricesRecord,
    storeScreenClustersIds
  );

  const storePricesRecord = filterRecordByKeys(
    clustersPricesRecord,
    storeScreenClustersIds
  );

  const storeTotalPricesRecord = filterRecordByKeys(
    clustersTotalPricesRecord,
    storeScreenClustersIds
  );
  const storeDiscountsRecord = filterRecordByKeys(
    clustersDiscountsRecord,
    storeScreenClustersIds
  );
  const paymentBill = calculateCampaignPriceByPriceRecords(
    storeSubtotalPricesRecord,
    storeDiscountsRecord
  );

  return {
    ...campaign,
    storePaymentBill: { ...campaign.PAYMENT_BILL, ...paymentBill },
    storeRevenue: {
      storeSubtotalPricesRecord,
      storeTotalPricesRecord,
      storePricesRecord,
      storeDiscountsRecord,
    },
  };
};

export const filterStoreMediaFromCampaign = (
  campaign: StoreOwnerIncomingCampaign,
  storeScreenClusters: ScreenCluster[],
  locationsList: LocationData[]
): StoreOwnerIncomingCampaign => {
  if (!campaign.PAYMENT_BILL) {
    return campaign;
  }

  const { MEDIA_LIST = [] } = campaign;

  const storeOwnerLocations = locationsList.filter((location) =>
    storeScreenClusters.some(
      (screenCluster) => screenCluster.ID === location.clusterId
    )
  );

  const filteredCampaignMediaList = MEDIA_LIST.filter((campaignMedia) =>
    storeOwnerLocations.some(
      (storeOwnerLocation) =>
        storeOwnerLocation.ID === campaignMedia.AD_SPACE_ID
    )
  );

  return {
    ...campaign,
    MEDIA_LIST: filteredCampaignMediaList,
  };
};
/**
 * @deprecated because of the remove of SCHEDULES entity from our domain.
 */
export const filterCampaignClusterSchedules = (
  campaign: StoreOwnerIncomingCampaign,
  storeScreenClusters: ScreenCluster[]
): StoreOwnerIncomingCampaign => {
  const { SELECTED_CLUSTERS_SCHEDULES: selectedClustersSchedules } = campaign;

  if (!selectedClustersSchedules) {
    return campaign;
  }

  const storeScreenClustersIds = storeScreenClusters.map(({ ID }) => ID);

  const filteredClustersSchedules = filterRecordByKeys(
    selectedClustersSchedules,
    storeScreenClustersIds
  )as ClustersSchedulesRecord;

  return {
    ...campaign,
    storeClustersSchedules: filteredClustersSchedules,
  };
};

export const filterCampaignClusters = (
  campaign: StoreOwnerIncomingCampaign,
  storeScreenClusters: ScreenCluster[]
): StoreOwnerIncomingCampaign => {
  const { storeClustersSchedules, SCREEN_CLUSTERS_IDS } = campaign;
  const selectedScreenClustersIds =
    SCREEN_CLUSTERS_IDS && SCREEN_CLUSTERS_IDS.length
      ? SCREEN_CLUSTERS_IDS
      : storeClustersSchedules&&Object.keys(storeClustersSchedules);
  const filteredScreenClusters = storeScreenClusters.filter(({ ID }) =>
    selectedScreenClustersIds?.includes(ID)
  );
  const filteredScreenClustersIds = filteredScreenClusters.map(({ ID }) => ID);

  return {
    ...campaign,
    storeClusters: filteredScreenClusters,
    storeClustersIds: filteredScreenClustersIds,
  };
};





export const getCampaignEndDateFromDateAndDuration = (campaign: StoreOwnerIncomingCampaign) => {
  const { DURATION_IN_WEEKS } = campaign;
  const startDate = getCampaignStartDate(campaign);
  return moment(startDate)
    .add(DURATION_IN_WEEKS, 'weeks')
    .toDate();
};
/** @deprecated because of the remove of SCHEDULES entity from our domain. **/
export const getCampaignStartDateFromSchedules = (campaign: StoreOwnerIncomingCampaign) => {
  const {
    SELECTED_CLUSTERS_SCHEDULES: selectedClustersSchedules = {},
  } = campaign;
  const schedules: Schedule[] = flatten(
    Object.values(selectedClustersSchedules)
  );

  if (!schedules.length) {
    return new Date();
  }

  const [{ START_DATE: campaignStartDate } = {} as Schedule] = schedules
    .map((schedule) => ({
      ...schedule,
      START_DATE: new Date(schedule.START_DATE).valueOf(),
    }))
    .sort(
      ({ START_DATE: leftStartDate }, { START_DATE: rightStartDate }) =>
        leftStartDate - rightStartDate
    );
  return new Date(campaignStartDate);
};


export const getCampaignStartDate = (campaign: StoreOwnerIncomingCampaign) => {
  if (campaign.START_DATE) {
    return campaign.START_DATE.toDate();
  }

  return getCampaignStartDateFromSchedules(campaign);
};

export const getCampaignEndDate = (campaign: StoreOwnerIncomingCampaign) => {
  if (campaign.START_DATE && campaign.DURATION_IN_WEEKS) {
    return getCampaignEndDateFromDateAndDuration(campaign);
  }
  return campaign.END_DATE.toDate();
};

const isCampaignApprovedOld = ({ STATUS }: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.APPROVED.VAL;

export const isCampaignApproved = ({
  APPROVED_BY_STORE_OWNER,
  STATUS,
}: Campaign) =>
  (STATUS.VAL === SYSTEM_STATUS.UNDER_PREPARATION.VAL &&
    APPROVED_BY_STORE_OWNER) ||
  isCampaignApprovedOld({ STATUS } as Campaign);

export const isCampaignRunningOrUpcoming = ({ STATUS }: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.RUNNING.VAL ||
  STATUS.VAL === SYSTEM_STATUS.UPCOMING.VAL;
export const isCampaignUpcoming = ({ STATUS }: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.UPCOMING.VAL;

export const isCampaignRunning = ({ STATUS }: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.RUNNING.VAL;
export const isCampaignFinished = ({ STATUS }: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.FINISHED.VAL;

export const isCampaignClosed = ({ STATUS }: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.FINISHED.VAL ||
  STATUS.VAL === SYSTEM_STATUS.CANCELED.VAL;

export const isCampaignUnderPreparation = ({ STATUS }: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.UNDER_PREPARATION.VAL;

export const isCampaignPendingAdvertiserAction = (campaign: Campaign) =>
  isCampaignPendingPayment(campaign) ||
  isCampaignRejected(campaign) ||
  isCampaignUnderPreparation(campaign);

export const isCampaignCanceled = ({ STATUS }: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.CANCELED.VAL;

export const isCampaignCompleted = (campaign: Campaign) =>
  isCampaignFinished(campaign) || isCampaignCanceled(campaign);

const isCampaignDraftOld = ({ STATUS }: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.NEW.VAL;

export const isCampaignDraft = ({ STATUS }: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.DRAFT.VAL ||
  isCampaignDraftOld({ STATUS } as Campaign);

export const isCampaignPendingPayment = ({ STATUS }: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.PENDING_PAYMENT.VAL;

export const isCampaignPendingStoreApproval = ({
  APPROVED_BY_STORE_OWNER,
  APPROVED_BY_ADMIN,
  STATUS,
}: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.UNDER_PREPARATION.VAL &&
  APPROVED_BY_ADMIN &&
  !APPROVED_BY_STORE_OWNER;

export const isCampaignPendingStoreOwnerApproval = (campaign: Campaign) =>
  isCampaignPendingStoreApproval(campaign) ||
  isCampaignPendingChangeMedia(campaign);

export const isCampaignPendingApproval = ({
  APPROVED_BY_STORE_OWNER,
  APPROVED_BY_ADMIN,
  STATUS,
}: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.UNDER_PREPARATION.VAL &&
  (!APPROVED_BY_STORE_OWNER || !APPROVED_BY_ADMIN);

export const isCampaignPendingChangeMedia = ({
  MEDIA_LIST = [],
  STATUS,
  APPROVED_BY_ADMIN,
  MEDIA_CHANGE_REQUEST_STATUS,
}: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.RUNNING.VAL &&
  APPROVED_BY_ADMIN &&
  (
    MEDIA_CHANGE_REQUEST_STATUS?.VAL === SYSTEM_STATUS.NEW.VAL ||
    MEDIA_LIST?.some((media) => media.MEDIA_CHANGE_REQUEST)
  );

const isCampaignRejectedOld = ({ STATUS }: Campaign) =>
  STATUS.VAL === SYSTEM_STATUS.REJECTED.VAL;

export const isCampaignRejected = ({
  APPROVED_BY_STORE_OWNER,
  STATUS,
  APPROVED_BY_ADMIN,
}: Campaign) =>
  (STATUS.VAL === SYSTEM_STATUS.REQUIRES_ACTION.VAL &&
    (!APPROVED_BY_ADMIN || !APPROVED_BY_STORE_OWNER)) ||
  isCampaignRejectedOld({ STATUS } as Campaign);

export const sortCampaignByStartDate = (
  leftCampaign: StoreOwnerIncomingCampaign,
  rightCampaign: StoreOwnerIncomingCampaign
) => {
  const leftStartDate = getCampaignStartDate(leftCampaign);
  const rightStartDate = getCampaignStartDate(rightCampaign);
  return leftStartDate > rightStartDate ? 1 : -1;
};

export const sortPendingStoreOwnerApprovalCampaignByRequestTime = (
  leftCampaign: Campaign,
  rightCampaign: Campaign
) => {
  const leftCampaignRequestDate = getDateFromServerTimeStamp(leftCampaign.APPROVED_BY_ADMIN_AT);
  const rightCampaignRequestDate = getDateFromServerTimeStamp(rightCampaign.APPROVED_BY_ADMIN_AT);
  return leftCampaignRequestDate > rightCampaignRequestDate ? 1 : -1;
};

export const sortCampaignsByStartDate = (campaigns: Campaign[]) =>
  campaigns.sort(sortCampaignByStartDate);

export const getCampaignTimeSpentPercentage = (campaign: StoreOwnerIncomingCampaign): number => {
  const startDate = getCampaignStartDate(campaign);
  const duration = getCampaignDuration(campaign);
  const endDate = getCampaignEndDate(campaign);
  const nowDate = new Date();
  const maxDate = Math.min(endDate.valueOf(), nowDate.valueOf());
  const maxDateMoment = moment(maxDate);
  const startDateMoment = moment(startDate);
  const returnFloatingDiffNumber = true; /** @see {@link https://devdocs.io/moment/index#/displaying/difference/} */
  const differenceInWeeks = maxDateMoment.diff(startDateMoment, 'weeks', returnFloatingDiffNumber);

  return Math.ceil(differenceInWeeks) / duration || 0;
};
// TODO: make this return type is Record<LocaleKey,string>
export const getCampaignRemainingDuration = (campaign: StoreOwnerIncomingCampaign): {number: number, key: string} => {
  const returnFloatingDiffNumber = true; /** @see {@link https://devdocs.io/moment/index#/displaying/difference/} */
  const endDateMoment = moment(getCampaignEndDate(campaign));
  const todayMoment = moment().startOf('day');
  const differenceInWeeks = endDateMoment.diff(todayMoment, 'weeks');
  const differenceInDays = Math.ceil(endDateMoment.diff(todayMoment, 'days', returnFloatingDiffNumber));
  if (isCampaignUpcoming(campaign) || isCampaignFinished(campaign)) {
    return pluralizeWeeks(campaign.DURATION_IN_WEEKS);
  }
  if (differenceInWeeks)
    return pluralizeWeeks(differenceInWeeks);
  return pluralizeDays(differenceInDays);
};

export const getCampaignDuration = (campaign: StoreOwnerIncomingCampaign): number => {
  const endDateMoment = moment(getCampaignEndDate(campaign));
  const startDateMoment = moment(getCampaignStartDate(campaign));

  return endDateMoment.diff(startDateMoment, 'weeks');
};

export const getCampaignAdvertiserCompanyName = (
  campaign: Campaign,
  advertisersList: AuthorizedUser[]
): string => {
  const { ADVERTISER_UID: advertiserUID } = campaign;
  const { COMPANY_NAME } =
    advertisersList.find(({ UID }) => UID === advertiserUID) ||
    ({} as AuthorizedUser);
  return COMPANY_NAME;
};

export const getFilteredCampaignMediaListByResolution = (
  mediaList: CampaignMedia[]
): CampaignMedia[] => {
  const filteredMediaList: CampaignMedia[] = [];

  mediaList?.forEach((media) => {
    if (
      !filteredMediaList.find(
        (filteredMedia) =>
          filteredMedia.WIDTH === media.WIDTH &&
          filteredMedia.HEIGHT === media.HEIGHT
      )
    ) {
      filteredMediaList.push(media);
    }
  });

  return filteredMediaList;
};

export const getCampaignMediaFileList = (campaign: Campaign): MediaFile[] => {
  const { MEDIA_LIST = [], MEDIA_FILE, MEDIA_TYPE } = campaign;

  const mediaList =
    MEDIA_LIST && MEDIA_LIST.length
      ? MEDIA_LIST
      : [
          {
            path: MEDIA_FILE,
            mime: MEDIA_TYPE,
            type: typeFromMime(MEDIA_TYPE),
          } as CampaignMedia,
        ];
  const filteredMediaList = getFilteredCampaignMediaListByResolution(
    mediaList
  );

  return filteredMediaList.map((media) => mapCampaignMediaToMediaFile(media));
};

/** @deprecated
 * because of moving the calculation of clusters prices separately to the server.
**/

const calculateStoreExpectedRevenueFromCampaignPaymentBill = (campaign: StoreOwnerIncomingCampaign): number => {
  return campaign.storeClustersIds!.reduce((totalPrice, clusterId) => {
    const priceAfterDiscount = campaign.storeRevenue?.storePricesRecord &&
      campaign.storeRevenue?.storePricesRecord[clusterId] -
      ((campaign.storeRevenue?.storeDiscountsRecord && campaign.storeRevenue?.storeDiscountsRecord[clusterId]?.discountValue) || 0)
    totalPrice += Math.ceil(priceAfterDiscount as number);
    return totalPrice;
  }, 0)
}

export const getCampaignExpectedFullEarning = (campaign: StoreOwnerIncomingCampaign): number => {
  if (campaign.storeRevenue?.storeTotalPricesRecord && Object.keys(campaign.storeRevenue.storeTotalPricesRecord).length) {
    return flow([values, sum])(campaign.storeRevenue.storeTotalPricesRecord);
  }
  return calculateStoreExpectedRevenueFromCampaignPaymentBill(campaign)

};

export const getAdvertiserCampaignTotalPrice = (campaign: Campaign): number => {
  if (!campaign.PAYMENT_BILL) {
    return 0;
  }
  return campaign.PAYMENT_BILL.TOTAL_PRICE;
};

export const getCampaignEarnedRevenue = (campaign: StoreOwnerIncomingCampaign): number | string => {
  const campaignNotStarted = isCampaignNotStartedYet(campaign);
  if (campaignNotStarted)
    return '-'
  return (
    getCampaignTimeSpentPercentage(campaign) *
    getCampaignExpectedFullEarning(campaign)
  );
};


export const getStoreCampaignCategory = (campaign: Campaign): string => {
  if (isCampaignCompleted(campaign)) {
    return 'completed_campaigns';
  }

  if (isCampaignUpcoming(campaign)) {
    return 'upcoming_campaigns';
  }
  if (isCampaignRunning(campaign)) {
    return 'running_campaigns';
  }

  if (isCampaignPendingStoreOwnerApproval(campaign)) {
    return 'pending_campaigns';
  }

  return '';
};


export const getAdvertiserCampaignCategory = ({ dbDataObject: campaign }: AdvertiserCampaign): string => {
  if (isCampaignDraft(campaign)) {
    return 'draft_campaigns';
  }

  if (isCampaignRunningOrUpcoming(campaign)) {
    return 'running_campaigns';
  }

  if (isCampaignPendingAdvertiserAction(campaign)) {
    return 'pending_campaigns';
  }

  if (isCampaignClosed(campaign)) {
    return 'completed_campaigns';
  }

  return '';
};

export const getCampaignRejectedBy = (campaign: Campaign): string => {
  const { APPROVED_BY_STORE_OWNER, APPROVED_BY_ADMIN } = campaign;

  if (!APPROVED_BY_STORE_OWNER) {
    return 'store_owner';
  }

  if (!APPROVED_BY_ADMIN) {
    return 'shasha_admin';
  }

  return '';
};


export const extractStoreCampaignData = (
  campaign: StoreOwnerIncomingCampaign,
  advertisersList: AuthorizedUser[],
  locationsList: LocationData[]
): StoreCampaign => {
  const {
    STATUS: status,
    NAME: name,
    APPROVED_BY_ADMIN_AT,
    MEDIA_CHANGE_REQUESTED_AT,
    ADVERTISER_UID,
    ID,
  } = campaign;
  const category = getStoreCampaignCategory(campaign);
  const startDate = getCampaignStartDate(campaign);
  const endDate = getCampaignEndDate(campaign);
  const durationInWeeks = getDuration(startDate, endDate);
  const advertiserCompanyName = getCampaignAdvertiserCompanyName(campaign, advertisersList);
  const mediaList = getCampaignMediaFileList(campaign);
  const approvedAt = getDateFromServerTimeStamp(APPROVED_BY_ADMIN_AT);
  const mediaChangeRequestedAt = getDateFromServerTimeStamp(MEDIA_CHANGE_REQUESTED_AT);
  const earnedRevenue = getCampaignEarnedRevenue(campaign);
  const expectedTotalEarning = getCampaignExpectedFullEarning(campaign);
  const remainingDuration = getDuration(new Date(), endDate);
  const locations = locationsList.filter(
    ({ clusterId }) =>
      campaign.storeClustersIds &&
      campaign.storeClustersIds.includes(clusterId)
  );
  const branchesNames = locations
    .map(({ BRANCH }) => BRANCH)
    .join(', ');
  const campaignRequest = {
    ADVERTISER_UID,
    ID,
  };

  return {
    name,
    startDate,
    endDate,
    status,
    category,
    locations,
    branchesNames,
    durationInWeeks,
    remainingDuration,
    advertiserCompanyName,
    mediaList,
    approvedAt,
    campaignRequest,
    earnedRevenue,
    expectedTotalEarning,
    mediaChangeRequestedAt,
  };
};
export const getDuration = (startDuration: Date, endDuration: Date): { number: number, key: string } => {
  const difference = Math.ceil(moment(endDuration).diff(moment(startDuration), 'day', true));
  if (difference % 7 === 0) {
    const startDate = moment(startDuration).startOf('day');
    const endDate = moment(endDuration).startOf('day');
    return pluralizeWeeks(endDate.diff(startDate, 'week'));
  } else {
    return pluralizeDays(difference);
  }
};
export const extractIncomingStoreOwnerCampaignDataForStoreOwner = (
  campaign: StoreOwnerIncomingCampaign,
  storeScreenClusters: ScreenCluster[],
  locationsList: LocationData[],
  storeOwner?: AuthorizedUser,
): StoreCampaign => {
  const {
    ADVERTISER_NAME: advertiserName,
    ID,
    STATUS,
    DURATION_IN_WEEKS,
  } = campaign;
  const category = getStoreCampaignCategory(campaign);
  const status = STATUS;
  const startDate = getCampaignStartDate(campaign);
  const endDate = getCampaignEndDate(campaign);
  const isStoreOwnerCampaign = (campaign as unknown as StoreOwnerCampaign).isStoreOwner
  const durationInWeeks = getDuration(startDate,endDate)
  const advertiserCompanyName = advertiserName!;
  const storeOwnerCompanyName = storeOwner?.COMPANY_NAME;
  const mediaList = getCampaignMediaFileList(campaign);
  const approvedAt = '-';
  const mediaChangeRequestedAt = '-';
  const expectedTotalEarning = getCampaignExpectedFullEarning(campaign) || 0;

  const regulatorLocationPrices = filterCampaignLocationsPricesByScreenCluster(campaign, storeScreenClusters)
  const totalPrices = reduce(regulatorLocationPrices, (total: any, locationPrice: LocationPrice['BASE_PRICE']) => {
    return total + Number(locationPrice)
  }, 0)
  let isFree;
  if (size(regulatorLocationPrices) && totalPrices === 0) {
    isFree = true
  }

  const remainingDuration = getDuration(new Date(), endDate);
  const isCampaignHasLocationId = (clusterId: Campaign['SCREEN_CLUSTERS_IDS'][0]) => (campaign.SCREEN_CLUSTERS_IDS &&
    campaign.SCREEN_CLUSTERS_IDS.includes(clusterId))
  const isShashaAdHasLocationId = (locationId: AdSpace['ID']) => ((campaign as unknown as ShashaAd).AD_SPACE_ID
    && (campaign as unknown as ShashaAd).AD_SPACE_ID === (locationId))
  const locations = locationsList.filter(
    ({ clusterId, ID: locationId }) => isCampaignHasLocationId(clusterId) || isShashaAdHasLocationId(locationId)
  );
  const branchesNames = locations
    .map(({ BRANCH }) => BRANCH)
    .join(', ');
  const isConnectedToNavori = locations.map(({ NAVORI_GROUP_ID }) => NAVORI_GROUP_ID).filter(Boolean).length > 0;
  const isLinkedWithRegulator = locations.map(({ REGULATOR_ID }) => REGULATOR_ID).filter(Boolean).length > 0;
  const campaignRequest = {
    ID,
    ADVERTISER_UID:'',
  };

  return {
    name: advertiserName!,
    status,
    startDate,
    endDate,
    category,
    locations,
    branchesNames,
    durationInWeeks,
    dbDurationInWeeks: DURATION_IN_WEEKS,
    remainingDuration,
    advertiserCompanyName,
    storeOwnerCompanyName,
    mediaList,
    approvedAt,
    campaignRequest,
    expectedTotalEarning,
    mediaChangeRequestedAt,
    isStoreOwnerCampaign,
    isFree,
    isConnectedToNavori,
    isLinkedWithRegulator,
    id: ID,
  };
};
const filterCampaignLocationsPricesByScreenCluster = (campaign: StoreOwnerIncomingCampaign, storeScreenClusters: ScreenCluster[]) => {
  const screenClustersIds = storeScreenClusters.map(screenCluster => screenCluster.ID)
  return filterRecordByKeys(
    mapValues(campaign.LOCATION_PRICES, (locationPrice: LocationPrice) => locationPrice.BASE_PRICE),
    screenClustersIds
  );
}
export const extractIncomingStoreOwnerCampaignDataForRegulator = (
  campaign: StoreOwnerIncomingCampaign,
  storeScreenClusters: ScreenCluster[],
  locationsList: LocationData[],
  storeOwnersUsersIdsMap: Record<AuthorizedUser['UID'], AuthorizedUser>,
): StoreCampaign => {
  const extractedCampaignData = extractIncomingStoreOwnerCampaignDataForStoreOwner(
    campaign, storeScreenClusters, locationsList, storeOwnersUsersIdsMap[(campaign as unknown as StoreOwnerCampaign).STORE_OWNER_UID]);
  const regulatorLocationPrices = filterCampaignLocationsPricesByScreenCluster(campaign, storeScreenClusters)
  if (!size(regulatorLocationPrices)) {
    extractedCampaignData.isBeforeMonitoring = true;
  }
  return extractedCampaignData
}

export const extractShashaAds = (
  campaign: StoreOwnerIncomingCampaign,
  storeScreenClusters: ScreenCluster[],
  locationsList: LocationData[],
): StoreCampaign => {
  const extractedCampaignData = extractIncomingStoreOwnerCampaignDataForStoreOwner(campaign, storeScreenClusters, locationsList);
  extractedCampaignData.earnedRevenue = 0
  extractedCampaignData.advertiserCompanyName = i18n.t('shasha').toString();
  extractedCampaignData.name = campaign.NAME;
  extractedCampaignData.durationInWeeks = {number: 1, key: 'day'};
  extractedCampaignData.remainingDuration = {number: 1, key: 'day'};
  (extractedCampaignData as unknown as ShashaAd).isShashaAd = true;
  return extractedCampaignData
}
export const extractMyCampaignData = (
  campaign: StoreOwnerIncomingCampaign,
  locationsList: LocationData[],
  userInfo: AuthorizedUser
): AdvertiserCampaign => {
  const {
    STATUS: status,
    NAME: name,
    REJECTED_AT,
    ADVERTISER_UID,
    ID,
    COMMENTS,
  } = campaign;
  const totalPrice = getAdvertiserCampaignTotalPrice(campaign);
  const startDate = getCampaignStartDate(campaign);
  const endDate = getCampaignEndDate(campaign);
  const durationInWeeks = getCampaignDuration(campaign);
  const mediaList = campaign.MEDIA_LIST
    ? getCampaignMediaFileList({
        ...campaign,
        MEDIA_LIST: getFilteredCampaignMediaListByResolution(
          campaign.MEDIA_LIST
        ),
      })
    : [];
  const rejectionReason = COMMENTS;
  const rejectedAt = getDateFromServerTimeStamp(REJECTED_AT);
  const rejectedBy = getCampaignRejectedBy(campaign);
  const locations = locationsList.filter(
    ({ clusterId }) =>
      campaign.SCREEN_CLUSTERS_IDS &&
      campaign.SCREEN_CLUSTERS_IDS.includes(clusterId)
  );
  const branchesNames = locations
    .map(({ BRANCH }) => BRANCH)
    .join(', ');

  const campaignRequest = {
    ADVERTISER_UID,
    ID,
  };
  return {
    name,
    startDate,
    endDate,
    status,
    locations,
    branchesNames,
    durationInWeeks,
    mediaList,
    campaignRequest,
    totalPrice,
    rejectionReason,
    rejectedAt,
    rejectedBy,
    dbDataObject: campaign,
  };
};
const campaignMediaChangePropertiesByStatus: Record<SystemStatus['VAL'], UpdatedCampaignPropertiesWhenMediaChanged> = {
  under_preparation: {
    APPROVED_BY_ADMIN: false,
    APPROVED_BY_STORE_OWNER: false,
    COMMENTS: '',
  },
  upcoming:{
    APPROVED_BY_ADMIN: false,
    APPROVED_BY_STORE_OWNER: false,
    STATUS: SYSTEM_STATUS.UNDER_PREPARATION,
    APPROVED_BY_ADMIN_AT: null,
    COMMENTS: '',
  },
  requires_action:{
    APPROVED_BY_ADMIN: false,
    APPROVED_BY_STORE_OWNER: false,
    STATUS: SYSTEM_STATUS.UNDER_PREPARATION,
    APPROVED_BY_ADMIN_AT: null,
    COMMENTS: '',
  },
  running:{
    APPROVED_BY_ADMIN: false,
    APPROVED_BY_STORE_OWNER: false,
    APPROVED_BY_ADMIN_AT:null,
    MEDIA_CHANGE_REQUEST_STATUS: SYSTEM_STATUS.NEW,
    COMMENTS: '',
    MEDIA_CHANGE_REQUESTED_AT: firestore.Timestamp.now(),
  }
}
export const updateCampaignPropertiesByStatusWhenMediaChange = (status: SystemStatus):UpdatedCampaignPropertiesWhenMediaChanged => {
  if(campaignMediaChangePropertiesByStatus[status.VAL]) {
    return campaignMediaChangePropertiesByStatus[status.VAL]
  }
  return {}
};

const isCampaignNotStartedYet = (campaign: StoreOwnerIncomingCampaign) => {
  const startDate = moment(getCampaignStartDate(campaign));
  return startDate.isAfter(moment(new Date()),'day')
}

export const isAllSelectedLocationsPriceControlByShasha=(selectedLocations:LocationData[])=>{
  return selectedLocations.every(location => location.isPriceControlByShasha)
}

export const convertCampaignTimestampsToDates = (
  playlistCampaign: PlaylistCampaign
): PlaylistCampaign & { startDateISOString: string, endDateISOString: string } => {
  return  {
    ...playlistCampaign,
    startDateISOString: new Date((playlistCampaign.START_DATE as unknown as SerializedFirebaseTimestamp)._seconds * 1000).toISOString(),
    endDateISOString: new Date((playlistCampaign.END_DATE as unknown as SerializedFirebaseTimestamp)._seconds * 1000).toISOString()
  };
}

export function isAdvertiserCampaign(campaign: PlaylistCampaign): campaign is PlaylistCampaign & Pick<Campaign, 'ADVERTISER_UID'> {
  return 'ADVERTISER_UID' in campaign;
}

export function isStoreOwnerCampaign(campaign: PlaylistCampaign): campaign is PlaylistCampaign & Pick<StoreOwnerCampaign, 'isStoreOwner'> {
  return 'isStoreOwner' in campaign;
}
