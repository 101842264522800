
import { Component, Vue } from 'vue-property-decorator';
import Media from '@/ui-components/Media/Media.vue';
import CampaignInfo from '@/ui-components/CampaignInfo/CampaignInfo.vue';
import CampaignStatus from '@/ui-components/CampaignStatus/CampaignStatus.vue';
import CampaignTimeLeft from '@/ui-components/CampaignTimeLeft/CampaignTimeLeft.vue';
import HeaderTitle from './components/HeaderTitle.vue';
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { GetStoreCampaignById } from '@/store/modules/store-module/types';
import { StoreCampaign } from '@/types/campaigns';
import CampaignMedia from '@/ui-components/CampaignMedia/CampaignMedia.vue';
import { SYSTEM_STATUS } from '@/statics/system-status';
import { AuthorizedUser } from '@/types/users';
import { head } from 'lodash';
import { USER_ROLES } from '@/statics/user-roles';
import IButton from '@/ui-components/IButton/IButton.vue';
import IDialog from '@/ui-components/IDialog/IDialog.vue';
import { cancelCampaignAction } from '@/actions/campaigns/store-owner';
import {
  LoadRunningCampaigns,
  LoadUpcomingCampaigns,
} from '@/store/modules/incoming-campaigns-module/types';
import { storeOwnerRouteNames } from '@/route-names/store-owner';

@Component({
  name: 'CampaignViewPage',
  components: {
    CampaignMedia,
    Media,
    HeaderTitle,
    CampaignStatus,
    CampaignTimeLeft,
    CampaignInfo,
    IButton,
    IDialog,
  },
})
export default class CampaignViewPage extends Vue {
  @Action('getStoreCampaignById', {
    namespace: namespaces.StoreModule,
  })
  public getStoreCampaignById!: GetStoreCampaignById;

  @Getter("userInfo", { namespace: namespaces.AuthModule })
  public userInfo!: AuthorizedUser;
  @Action('loadRunningCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public loadRunningCampaigns!: LoadRunningCampaigns;

  @Action('loadUpcomingCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public loadUpcomingCampaigns!: LoadUpcomingCampaigns;
  public campaign: StoreCampaign | null = null;
  public showConfirmCancelCampaign = false;
  public loading = false;

  public get campaignId() {
    return this.$route.params.id;
  }
  get isRunningCampaign(){
    return this.campaign?.status?.VAL === SYSTEM_STATUS.RUNNING.VAL;
  }
  public async getCampaignData() {
    this.campaign = await this.getStoreCampaignById(this.campaignId);
    if (!this.campaign || !this.campaignId) {
      this.$router.go(-1);
    }
  }

  public created() {
    this.getCampaignData();
  }

  close() {
    this.showConfirmCancelCampaign = false
  }

  checkStoreOwnerUser() {
    return head(this.userInfo?.ROLES)?.VAL === USER_ROLES.STORE_OWNER.VAL
  }

  checkStoreOwnerCampaign() {
    return this.campaign?.isStoreOwnerCampaign && this.campaign?.isConnectedToNavori && !this.campaign.isLinkedWithRegulator;
  }

  checkCampaignStatus() {
    return this.campaign?.status.VAL === SYSTEM_STATUS.RUNNING.VAL || this.campaign?.status.VAL === SYSTEM_STATUS.UPCOMING.VAL;
  }

  get showCancelCampaignButton() {
    return this.checkStoreOwnerUser() && this.checkStoreOwnerCampaign() && this.checkCampaignStatus();
  }
  get showEditCampaignButton() {
    return this.checkStoreOwnerUser() && this.campaign?.isStoreOwnerCampaign && this.checkCampaignStatus();
  }

  get confirmDialogSubtitle() {
    const subtitle = {
      [SYSTEM_STATUS.RUNNING.VAL]: this.$t('confirm_cancel_campaign_running_description').toString(),
      [SYSTEM_STATUS.UPCOMING.VAL]: this.$t('confirm_cancel_campaign_upcoming_description').toString(),
    }
    return subtitle[this.campaign?.status.VAL || ''] || ''
  }

  tabsCategoriesActionsMap: { [key: string]: () => Promise<void> } = {
    [SYSTEM_STATUS.RUNNING.VAL]: this.loadRunningCampaigns,
    [SYSTEM_STATUS.UPCOMING.VAL]: this.loadUpcomingCampaigns,
  };

  async cancelCampaign() {
    try {
      this.loading = true
      console.log(this.campaign)
      await cancelCampaignAction(this.campaign!)
      const reloadCampaigns = this.tabsCategoriesActionsMap[this.campaign?.status.VAL || '']
      await reloadCampaigns?.()
      this.$router.go(-1);
    } catch (error) {
      console.log('error', error)
    } finally {
      this.loading = false
    }
  }
  goToEditCampaignPage(){
    this.$router.push({
      ...storeOwnerRouteNames.STORE_OWNER_EDIT_CAMPAIGN,
    })
  }
}
