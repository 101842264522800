import { AuthorizedUser, UserRouteNames } from '@/types/users';
import { userRouteNamesMap } from '@/statics/user-route-names-map';
import { Role } from '@/types/users';
import { USER_ROLES } from '@/statics/user-roles';
import { VueRouter } from 'vue-router/types/router'
export const getUserRoleValue = ([ userRole = {} as Role ]: Role[]): string => userRole.VAL;

export const getUserRouteNames =
  (roleValue: string): UserRouteNames => userRouteNamesMap[roleValue];

export const redirectUserToAuthorizedRoleDashboard = async (router: VueRouter, userInfo?: AuthorizedUser): Promise<boolean> => {
  const isUserRoleAdvertiser = userInfo?.ROLES?.some(role => role.VAL === USER_ROLES.ADVERTISER.VAL);
  if (userInfo&&!isUserRoleAdvertiser) {
    const userRouteNames = getUserRouteNames(userInfo?.ROLES?.[0].VAL || '');
    await router.replace(userRouteNames.DASHBOARD);
  }
  return Boolean(isUserRoleAdvertiser)
}