import { LocationData } from '@/types/locations';
import { FirebaseRemoteConfig } from '@/firebase/firebase-app';
import firebaseNames from '@/statics/firebase-names';
import { AdSpace } from '@/types/adspaces';

export const sortLocationsByPriorityOfAppearance = (locations: LocationData[]) => {
  return locations.sort((a, b) => {
    if (a.PRIORITY === undefined) return 1;
    if (b.PRIORITY === undefined) return -1;
    return a.PRIORITY - b.PRIORITY;
  });
}

