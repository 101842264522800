
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import RegisterForm from '@/pages/public/Register/components/RegisterForm.vue';
import LoginForm from '@/pages/public/Login/components/LoginForm.vue';
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { AuthorizedUser } from '@/types/users';
import AlreadyRegisteredUserProfile from '@/pages/shared/CampaignWizard/components/Account/components/AlreadyRegisteredUserProfile.vue';
import NewRegisteredUserProfile from '@/pages/shared/CampaignWizard/components/Account/components/NewRegisteredUserProfile.vue';
import TextField from '@/ui-components/TextField/TextField.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import {
  CheckAndUpdateAnonymousMedia,
  UpdatePayment,
  UpdateStep
} from '@/store/modules/campaign-wizard/types';
import { MediaData } from '@/types/media';
import { Nullable } from '@/types/misc';
import { PaymentData } from '@/types/payment';
import { ShowSnackbar } from '@/types/snackbar';
import { Campaign } from '@/types/campaigns';
import { isUniqueCampaignName } from '@/actions/campaigns/actions';
import { redirectUserToAuthorizedRoleDashboard } from "@/utils/user";

@Component({
  components: {
    IButton,
    TextField,
    NewRegisteredUserProfile,
    AlreadyRegisteredUserProfile,
    LoginForm,
    RegisterForm,
  },
})
export default class Account extends Vue {
  @Prop({ type: Boolean, default: false }) active!: boolean;

  @Getter('userInfo', { namespace: namespaces.AuthModule })
  public userInfo!: AuthorizedUser;

  @Getter('media', { namespace: namespaces.CampaignWizardModule })
  public media!: MediaData;

  @Getter('name', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardCampaignName!: string;

  @Getter('savedCampaign', { namespace: namespaces.CampaignWizardModule })
  public savedCampaign!: Campaign|null;

  @Getter('payment', { namespace: namespaces.CampaignWizardModule })
  public paymentData!: PaymentData;

  @Getter('step', { namespace: namespaces.CampaignWizardModule })
  public currentStep!: number;

  @Action('updatePayment', { namespace: namespaces.CampaignWizardModule })
  public updatePayment!: UpdatePayment;

  @Action('checkAndUpdateAnonymousMedia', { namespace: namespaces.CampaignWizardModule })
  public checkAndUpdateAnonymousMedia!: CheckAndUpdateAnonymousMedia;

  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  @Action('updateStep', { namespace: namespaces.CampaignWizardModule })
  public updateStep!: UpdateStep;

  isRegisteredUser = false;
  isNewUser = false;

  async onLoginSuccess() {
    const isUserRoleAdvertiser = await redirectUserToAuthorizedRoleDashboard(this.$router,this.userInfo);
    if (isUserRoleAdvertiser) {
      this.isNewUser = false;
    }
  }

  onRegisterSuccess() {
    this.isNewUser = true;
  }

  async submitNextOrPrevious() {

    const isNewCampaignName = await isUniqueCampaignName(this.campaignWizardCampaignName, this.savedCampaign?.ID || '')
    if (isNewCampaignName) {
      this.nextStep()
    } else {
      this.previousStep()
    }
  }
  @Emit()
  nextStep() {
    return true;
  }

  @Emit()
  previousStep() {
    return true;
  }

  @Watch('userInfo', { deep: true })
  async authenticationChange(val: Nullable<AuthorizedUser>, prevVal: Nullable<AuthorizedUser>) {
    const hasUserLoggedIn = !prevVal && val;

    if (hasUserLoggedIn) {
      try {
        await this.checkAndUpdateAnonymousMedia();
      } catch {
      }

    }
  }
  async created(){
    const isNewCampaignName = await isUniqueCampaignName(this.campaignWizardCampaignName, this.savedCampaign?.ID || '')
    if (!isNewCampaignName) {
      this.previousStep()
    }
  }
}
