
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import IDialog from '@/ui-components/IDialog/IDialog.vue';
import IButton from '@/ui-components/IButton/IButton.vue';

@Component({
    name: 'PublishConfirmationDialog',
    components: {
        IDialog,
        IButton
    },
})
export default class PublishConfirmationDialog extends Vue {
    isOpen = false;

    openDialog() {
        this.isOpen = true;
    }

    closeDialog() {
        this.isOpen = false;
    }

    @Emit('save')
    saveAction() {
        this.closeDialog();
    }
}
