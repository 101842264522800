/**
 * Module for firebase operations. Firebase Services included here:
 * 1. Firebase application.
 * 2. Firebase Firestore database. Used for the Data model.
 * @author aelsayed@mycorr.co
 */
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/remote-config';
import firebaseApp from 'firebase/app';
import { authStateWatcher } from './firebase-user';
import firebaseNames from '@/statics/firebase-names';

const { app, apps, initializeApp } = firebaseApp;

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FB_DB_URL,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FB_APP_ID,
};

const getFirebaseApp = (): firebaseApp.app.App => {
  if (apps.length > 0) {
    return app();
  } else {
    return initializeApp(firebaseConfig, process.env.VUE_APP_FB_APP_NAME);
  }
};

/**
 * Firebase SDK application.
 */
export const FirebaseSdkApp = getFirebaseApp();


/** Firebase App Auth. */
export const FirebaseAppAuth = FirebaseSdkApp.auth();

/** Firebase App Firestore. */
export const FirebaseAppFirestore = FirebaseSdkApp.firestore();

FirebaseAppFirestore.settings({ ignoreUndefinedProperties: true });

/** Firebase App Functions. */
export const FirebaseAppFunctions = FirebaseSdkApp.functions();
if (process.env.NODE_ENV === 'development') {
   FirebaseAppFunctions.useEmulator('localhost',5001);
}

/** Firebase App Storage. */
export const FirebaseAppStorage = FirebaseSdkApp.storage();

/** Firebase App Remote Config */
export const FirebaseRemoteConfig = FirebaseSdkApp.remoteConfig();

// Firebase App Remote Config settings
FirebaseRemoteConfig.settings = {
  fetchTimeoutMillis: 5000,
  minimumFetchIntervalMillis: 300000,
};

// Firebase App Remote Config default parameter values
FirebaseRemoteConfig.defaultConfig = ({
  [firebaseNames.remoteConfig.PAY_LATER_FOR_CAMPAIGN]: false,
  [firebaseNames.remoteConfig.SHOW_ONLY_ACTIVE_STORES_ON_PLATFORM]: false,
  [firebaseNames.remoteConfig.PENDING_PAYMENT_TIMEOUT_IN_HOURS]: 24,
  [firebaseNames.remoteConfig.MEDIA_FILE_SIZE_LIMIT_IN_MEGA_BYTES]: 50,
  [firebaseNames.remoteConfig.ACTIVE_STORES_ON_PLATFORM]: `{
    "V5OJ5o5ApsRg3S3byDsegq16AO83": "AlAmer Markets",
    "FwGP0VHQIESPKm0eCUgLnLP9Glh2": "Lulu East",
    "il6RAWG2JqYreWIijje2dmQMa1u2": "Tihama"
  }`,
});

authStateWatcher();
