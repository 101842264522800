import { publicRouteNames } from '@/route-names/public';

export const defaultAppName = 'shasha';
export const shashaHomeUrl = process.env.VUE_APP_HOME_URL || 'https://shasha.io';
export const termsAndConditionsLink = 'https://shasha.io/en/terms-and-conditions';
export const privacyPolicyLink = 'https://shasha.io/en/privacy-policy';
export const returnPolicyLink = 'https://shasha.io/en/return-policy';
const PROJECTS = {
  LOCALHOST: 'localhost',
  STAGING: 'shasha-staging',
  DEVELOPMENT: 'development',
  QA: 'shasha-quality-assurance',
  PRODUCTION: 'shasha-production',
};
const HostNames: { [projectId: string]: string } = {
  [PROJECTS.LOCALHOST]: 'http://localhost:8080',
  [PROJECTS.STAGING]: 'https://shasha-platform-staging.web.app',
  [PROJECTS.QA]: 'https://shasha-platform-qa.web.app',
  [PROJECTS.PRODUCTION]: 'https://app.shasha.io',
};

export const HostName = HostNames[process.env.VUE_APP_FB_APP_NAME || ''];

export const pathsNotResetCache = [publicRouteNames.RESET_PASSWORD.name]
export const FILTER_QUERY_LIMIT = 10
