
import { Component, Vue } from 'vue-property-decorator';
import Upload from '@/shared-components/Upload.vue';
import { CampaignMedia, MediaFile, MediaResolution } from "@/types/media";
import namespaces from '@/store/namespaces';
import { Getter } from 'vuex-class';
import { LocationData } from "@/types/locations";
import {
  getAbbreviatedUserType,
  loadCampaignByIdAndUserId,
  loadCampaignLocations,
  updateCampaignMediaList
} from "@/actions/campaigns/actions";
import MediaUploadGuidelines from "@/shared-components/MediaUploadGuidelines.vue";
import { Campaign } from "@/types/campaigns";
import IButton from '@/ui-components/IButton/IButton.vue';
import { Nullable } from "@/types/misc";
import { getUserInfoById } from "@/actions/users/actions";
import { AuthorizedUser } from "@/types/users";
import {
  mapMediaToLocations,
  mergeMediaListFromOldAndNewMedia,
  mapCampaignMediaToMediaFile,
  mapMediaResolutionsToCampaignSelectedLocations,
  mapMediaToLocationsWithMetadata,
} from "@/utils/media";
import { logoutUserAction } from "@/actions/auth/actions";
import { getCurrentUser } from "@/firebase/firebase-user";
import { sharedRouteNames } from "@/route-names/shared";
import {
  updateCampaignPropertiesByStatusWhenMediaChange,
  getFilteredCampaignMediaListByResolution,
} from '@/utils/campaigns';

@Component({
  components: {
    IButton,
    MediaUploadGuidelines,
    Upload,
  },
  beforeRouteEnter: async (to, from, next) => {
    const user = await getCurrentUser()
    if (user) {
      const { claims: { UID, ADVERTISER_UID, CAMPAIGN_ID } } = await user.getIdTokenResult()
      const { uid, advertiserUid, campaignId } = to.params;
      if (UID !== uid || ADVERTISER_UID !== advertiserUid || CAMPAIGN_ID !== campaignId) {
        next({ ...sharedRouteNames.PAGE_NOT_FOUND })
      }

    } else {
      next({ ...sharedRouteNames.PAGE_NOT_FOUND })
    }
    next();
  },
})
export default class DesignUpload extends Vue {
  public acceptedFileTypes = ['jpg', 'jpeg', 'mp4'];
  public media: MediaFile[] = [];
  public oldMedia: CampaignMedia[] = [];
  public selectedLocations: LocationData[] = [];
  public campaignData: Nullable<Campaign> = null;
  public userData: Nullable<AuthorizedUser> = null;
  public loading = false;
  public canSubmit = false;
  public sheet = false;

  @Getter('isAuthenticated', { namespace: namespaces.AuthModule })
  public isAuthenticated!: boolean;

  public acceptedMediaResolution({
                                   width = 0,
                                   height = 0,
                                   unit = 'px',
                                 }: MediaFile): MediaResolution {
    return { width, height, unit };
  }

  checkMediaAndEnableSubmitBtn() {
    if (Boolean(this.media.length) && this.media.every(media => Boolean(media.path))) {
      this.canSubmit = true;
    }
  }

  public handleMediaFileChanged(change: MediaFile | null, index: number) {
    if (change) {
      if (this.media[index].path) {
        this.canSubmit = false;
      }
      this.media[index] = change;
      this.media = [...this.media];
      this.checkMediaAndEnableSubmitBtn()
    }

  }

  public async created() {
    this.loading = true;
    const promises: Promise<any>[] = [];
    promises.push(getUserInfoById(this.$route.params.advertiserUid) as Promise<AuthorizedUser>);
    promises.push(loadCampaignByIdAndUserId(this.$route.params.campaignId, this.$route.params.advertiserUid) as Promise<Campaign>);
    const results = await Promise.all(promises);
    const data = results[1] as Campaign;
    this.userData = results[0] as AuthorizedUser;
    this.campaignData = data;
    if (data) {
      this.selectedLocations = await loadCampaignLocations(data.SCREEN_CLUSTERS_IDS);

      const uniqueMediaList = getFilteredCampaignMediaListByResolution(data.MEDIA_LIST!)
      const mappedMedia = uniqueMediaList?.map(campaignMedia => mapCampaignMediaToMediaFile(campaignMedia))
      this.media = mapMediaResolutionsToCampaignSelectedLocations(this.selectedLocations, mappedMedia || this.media)
      const oldMedia = mapMediaResolutionsToCampaignSelectedLocations(this.selectedLocations, mappedMedia || this.media);
      this.oldMedia = mapMediaToLocations({ mediaItems: oldMedia, locations: this.selectedLocations });
      this.checkMediaAndEnableSubmitBtn()
    } else {
      this.$router.push({ ...sharedRouteNames.PAGE_NOT_FOUND });
    }
    this.loading = false;
  }

  async submitUpload() {
    try {
      this.canSubmit = false;
      if (this.campaignData) {
        const {
          ADVERTISER_UID: userId,
          ID: campaignId,
          NAME: campaignName,
          DESIGNER_EMAIL: designerEmail,
          MEDIA_LIST: campaignMediaList,
          STATUS: status,
        } = this.campaignData;
        const { ROLES, COMPANY_NAME: companyName } = await getUserInfoById(userId)
        const abbreviatedUserType = getAbbreviatedUserType(ROLES || [])
        let mediaList = mapMediaToLocationsWithMetadata({
          mediaItems: this.media,
          locations: this.selectedLocations,
          designerEmail,
          campaignName,
          abbreviatedUserType,
          companyName
        });
        if (campaignMediaList?.length) {
          mediaList = mergeMediaListFromOldAndNewMedia(status, this.oldMedia, mediaList);
        }

        const updatedCampaignPropertiesByStatus = updateCampaignPropertiesByStatusWhenMediaChange(status);

        await updateCampaignMediaList({
          userId,
          campaignId,
          invalidateDesignerAccessCode: true,
          mediaList,
          updatedCampaignPropertiesByStatusWhenMediaChanged: updatedCampaignPropertiesByStatus
        });
        await logoutUserAction();
        this.$router.push({ ...sharedRouteNames.UPLOAD_SUCCESS })
      }

      this.canSubmit = true;
    } catch (e) {

    }
  }
}
