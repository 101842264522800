import { CampaignMedia, MediaFile, MediaNameFactory, MediaType } from '@/types/media';
import { extensionFromMime, nameFromPath, typeFromMime } from './files';
import { LocationData } from "@/types/locations";
import { uniqueArray } from '@/utils/misc';
import { SystemStatus } from '@/types/company-profile';
import { SYSTEM_STATUS } from '@/statics/system-status';
import firebase from 'firebase';
import { Campaign } from '@/types/campaigns';
import { UserInfo } from '@/types/users';

export const mapMediaFileToCampaignMedia = (
  media: MediaFile
): CampaignMedia => {
  const {
    mime,
    path,
    uploadedAt,
    playBackDuration,
    adSpaceId,
    width,
    height,
    mediaId,
    name
  } = media;

  return {
    MEDIA_UPLOADED_AT: uploadedAt,
    MEDIA_FILE: path!,
    MEDIA_TYPE: mime as MediaType,
    MEDIA_SKIPPED: false,
    MEDIA_PLAYBACK_DURATION: playBackDuration || null,
    AD_SPACE_ID: adSpaceId || '',
    WIDTH: width,
    HEIGHT: height,
    MEDIA_ID: mediaId,
    NAME: name,
  };
};

export const mapCampaignMediaToMediaFile = (
  media: CampaignMedia
): MediaFile => {
  const {
    MEDIA_FILE: path,
    MEDIA_PLAYBACK_DURATION: playBackDuration,
    MEDIA_TYPE: mime,
    MEDIA_UPLOADED_AT: uploadedAt,
    MEDIA_UPLOADED_BY: uploadedBy,
    WIDTH: width,
    HEIGHT: height,
    AD_SPACE_ID: adSpaceId,
    MEDIA_CHANGE_REQUEST,
    NAME,
    MEDIA_ID
  } = media;

  const changeRequestMedia = MEDIA_CHANGE_REQUEST && mapCampaignMediaToMediaFile(MEDIA_CHANGE_REQUEST);

  const type = typeFromMime(mime);
  return {
    name: NAME,
    path,
    type,
    mime,
    playBackDuration,
    width,
    height,
    unit: 'px',
    uploadedAt,
    adSpaceId,
    changeRequestMedia,
    uploadedBy,
    mediaId: MEDIA_ID,
  };
};

/**
 * Maps media items to their corresponding locations by matching screen resolutions.
 * Creates a list of CampaignMedia objects where each media file is assigned to 
 * a location with matching screen dimensions.
 * 
 * @param {Object} params - The parameters object
 * @param {MediaFile[]} params.mediaItems - Array of media files to be mapped
 * @param {LocationData[]} params.locations - Array of locations with screen resolution data
 * @returns {CampaignMedia[]} Array of campaign media objects with location assignments
 */
export const mapMediaToLocations = (
  {
    mediaItems,
    locations,
  }: {
    mediaItems: MediaFile[],
    locations: LocationData[],
  }): CampaignMedia[] => {

  return locations?.map(
    (location) => {
      const mediaForLocation = mediaItems.find(
        (media) =>
          media?.width === location.SCREENS_RESOLUTION.WIDTH &&
          media.height === location.SCREENS_RESOLUTION.HEIGHT
      );

      return {
        MEDIA_FILE: mediaForLocation!.path!,
        MEDIA_TYPE: mediaForLocation!.mime! as MediaType,
        MEDIA_PLAYBACK_DURATION:
          mediaForLocation!.playBackDuration || null,
        MEDIA_UPLOADED_AT: mediaForLocation?.uploadedAt || firebase.firestore.Timestamp.now(),
        WIDTH: mediaForLocation?.width,
        HEIGHT: mediaForLocation?.height,
        MEDIA_ID: mediaForLocation?.mediaId,
        NAME: mediaForLocation?.name,
        AD_SPACE_ID: location.ID,
      };
    }
  );
};

/**
 * Maps media items to locations and generates customized media names based on user information.
 * Similar to mapMediaToLocations but adds user-specific metadata and custom naming.
 * The function generates media names using the format: "{userType}--{company}--{campaign}--{mediaId}.{extension}"
 * 
 * @param {Object} params - The parameters object
 * @param {MediaFile[]} params.mediaItems - Array of media files to be mapped
 * @param {LocationData[]} params.locations - Array of locations with screen resolution data
 * @param {string} [params.designerEmail] - Email of the designer who uploaded the media
 * @param {string} [params.campaignName] - Name of the campaign
 * @param {string} [params.abbreviatedUserType] - Short code for user type (e.g., 'ADM' for admin)
 * @param {string} [params.companyName] - Name of the company
 * @returns {CampaignMedia[]} Array of campaign media objects with location assignments and custom naming
 * @example
 * // Generated media name example: "ADM--CompanyXYZ--Summer2024--abc123.jpg"
 */
export const mapMediaToLocationsWithMetadata = (
  {
    mediaItems,
    locations,
    designerEmail,
    campaignName,
    abbreviatedUserType,
    companyName,
  }: {
    mediaItems: MediaFile[],
    locations: LocationData[],
    designerEmail?: string
    campaignName?: Campaign['NAME'],
    abbreviatedUserType?: string,
    companyName?: UserInfo['COMPANY_NAME']
  }): CampaignMedia[] => {

  return locations?.map(
    (location) => {
      const mediaForLocation = mediaItems.find(
        (media) =>
          media?.width === location.SCREENS_RESOLUTION.WIDTH &&
          media.height === location.SCREENS_RESOLUTION.HEIGHT
      );
      const mediaName = mediaNameFactory({
        abbreviatedUserType,
        companyName,
        mediaForLocation,
        campaignName
      });
      return {
        MEDIA_FILE: mediaForLocation!.path!,
        MEDIA_TYPE: mediaForLocation!.mime! as MediaType,
        MEDIA_PLAYBACK_DURATION:
          mediaForLocation!.playBackDuration || null,
        MEDIA_UPLOADED_AT: firebase.firestore.Timestamp.now(),
        WIDTH: mediaForLocation?.width,
        HEIGHT: mediaForLocation?.height,
        MEDIA_ID: mediaForLocation?.mediaId,
        NAME: mediaName,
        AD_SPACE_ID: location.ID,
        ...designerEmail && { MEDIA_UPLOADED_BY: designerEmail }
      };
    }
  );
};

/**
 * @description
 * Merges the campaign media list with the unique list of locations resolutions
 */
export const mapMediaResolutionsToCampaignSelectedLocations = (selectedLocations: LocationData[], media: MediaFile[]): MediaFile[] => {

  if (!selectedLocations.length) {
    return [];
  }

  const requiredResolutions = uniqueArray(selectedLocations, (location) => location.SCREENS_RESOLUTION)
  const mediaFiles = media || [];

  const uploadedMediaFiles = mediaFiles.filter(mediaFile => mediaFile && mediaFile.path);
  const uploadedResolutions = uploadedMediaFiles.map((mediaFile) => ({
    WIDTH: mediaFile?.width,
    HEIGHT: mediaFile?.height,
    UNIT: mediaFile?.unit,
  }));

  const nonUploadedResolutions = requiredResolutions.filter((resolution) =>
    !uploadedResolutions.find((uploadedResolution) =>
      resolution.WIDTH === uploadedResolution?.WIDTH &&
      resolution.HEIGHT === uploadedResolution?.HEIGHT
    )
  ).filter(Boolean);
  const mediaPlaceholdersForNonUploadedResolutions = nonUploadedResolutions.map(
    ({ WIDTH, HEIGHT }) =>
      ({ width: WIDTH, height: HEIGHT } as MediaFile)
  );

  const updatedMediaFiles = [
    ...uploadedMediaFiles,
    ...mediaPlaceholdersForNonUploadedResolutions,
  ];
  return [...updatedMediaFiles];
};

export const mergeMediaListFromOldAndNewMedia = (status: SystemStatus, oldMediaList: CampaignMedia[], newMediaList: CampaignMedia[]): CampaignMedia[] => {
  return oldMediaList.map((oldMedia) => {
    const updatedMedia = newMediaList.find(newMedia => newMedia.AD_SPACE_ID === oldMedia.AD_SPACE_ID);
    const isMediaChanged = oldMedia.MEDIA_FILE !== updatedMedia?.MEDIA_FILE;

    if (!isMediaChanged) {
      return oldMedia;
    }
    if (isMediaChanged && status.VAL === SYSTEM_STATUS.RUNNING.VAL) {
      return {
        ...oldMedia,
        MEDIA_CHANGE_REQUEST: {
          ...updatedMedia,
          MEDIA_UPLOADED_AT: firebase.firestore.Timestamp.now(),
          STATUS: SYSTEM_STATUS.PENDING_APPROVAL,
        } as CampaignMedia
      } as CampaignMedia;
    } else {
      return {
        ...updatedMedia,
        MEDIA_UPLOADED_AT: firebase.firestore.Timestamp.now(),
        MEDIA_CHANGE_REQUEST: null,
      } as CampaignMedia;
    }
  });
}

export const getFileExtension = (fileName: MediaFile['name']) => {
  return fileName?.split('.').pop()
}
export const mediaNameFactory = ({
                                   abbreviatedUserType,
                                   companyName,
                                   campaignName,
                                   mediaForLocation
                                 }: MediaNameFactory) => {
  const extension = mediaForLocation?.name?.split('.').pop() || '';
  return `${abbreviatedUserType || ''}--${companyName || ''}--${campaignName || ''}--${mediaForLocation?.mediaId || ''}.${extension.toLowerCase()}`
}
