import { NavButtonData } from '@/types/nav-buttons';
import { publicRouteNames } from '@/route-names/public';
import { storeOwnerRouteNames } from '@/route-names/store-owner';
import { toggleLanguage } from '@/utils/locale';
export const advertiserNavButtons: NavButtonData[] = [
  {
    name: 'toggle_language',
    icon:'/assets/icons/language.svg',
    action: ()=>{
      toggleLanguage();
    }

  }
];

export const storeOwnerNavButtons: NavButtonData[] = [
  {
    name: 'toggle_language',
    icon:'/assets/icons/language.svg',
    action: ()=>{
      toggleLanguage();
    }

  },
];

export const sharedNavButtons: NavButtonData[] = [
  {
    name: 'toggle_language',
    icon:'/assets/icons/language.svg',
    action: ()=>{
      toggleLanguage();
    }

  }
];