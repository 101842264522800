
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import DatePicker from "@/ui-components/DatePicker/DatePicker.vue";
import TextField from "@/ui-components/TextField/TextField.vue";
import DropdownMenu from "@/ui-components/DropdownMenu/DropdownMenu.vue";

@Component({
  name: "DatePickerInput",
  components: {
    DatePicker,
    TextField,
    DropdownMenu,
  },
})
export default class DatePickerInput extends Vue {
  @Prop() value!: string;
  @Prop() inputValue!: string;
  @Prop({ type: String }) label!: string;
  @Prop({ type: String }) hint!: string;
  @Prop({ type: String }) placeholder!: string;
  @Prop({ type: Boolean }) showAdjacentMonths!: boolean;
  @Prop({ type: Boolean }) range!: boolean;
  @Prop({ type: String }) min!: string;
  @Prop({ type: Function, default: () => {} }) onSelect!: any;
  @Prop({ type: String }) appendIcon!: string;
  @Prop({ type: Array }) rules!: [(val: any) => boolean | string];

  public isOpen = false;

  public get isInputValueExists() {
    return Boolean(this.inputValue || this.inputValue === '');
  }

  @Emit("input")
  public updateInput(date: string|string[]) {

    if (
      typeof date === 'string' && date
      || Array.isArray(this.value) && this.value[1] === null
    ) {
      this.isOpen = false;
    }
    this.onSelect(date);
    return date;
  }
}
