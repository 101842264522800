import {
  changeCampaignMediaList,
} from '@/actions/campaigns/advertiser';
import {
  CampaignMedia,
  MediaFile,
} from '@/types/media';
import {
  isCampaignRunningOrUpcoming,
  isCampaignDraft,
  isCampaignClosed,
  isCampaignPendingAdvertiserAction,
  updateCampaignPropertiesByStatusWhenMediaChange,
} from '@/utils/campaigns';
import { AuthorizedUser } from '@/types/users';
import { AdvertiserState, ChangeMediaData } from './types';
import { RootState } from '@/store/types';
import { ActionTree, ActionContext, Dispatch } from 'vuex';
import { LocationData } from '@/types/locations';
import {
  loadMyCampaigns,
} from '@/actions/campaigns/advertiser';
import {
  extractMyCampaignData,
  sortCampaignByStartDate,
} from '@/utils/campaigns';
import { SYSTEM_STATUS } from '@/statics/system-status';
import moment from 'moment';
import { mapMediaFileToCampaignMedia } from '@/utils/media';
import firebase from 'firebase';

const checkAndLoadLocations = async (rootGetters: any, dispatch: Dispatch) => {
  const locations = rootGetters['CommonModule/locations'];

  if (!locations || !locations.length) {
    await dispatch('CommonModule/loadLocations', null, { root: true });
  }
};

export const actions: ActionTree<AdvertiserState, RootState> = {
  resetAllCampaigns: async ({
    commit,
  }: ActionContext<AdvertiserState, RootState>) => {
    commit('resetAllCampaigns');
  },
  loadMyCampaigns: async ({
    state: { myCampaigns },
    commit,
    rootGetters,
    dispatch,
  }: ActionContext<AdvertiserState, RootState>) => {
    await checkAndLoadLocations(rootGetters, dispatch);

    const locationsList: LocationData[] = rootGetters['CommonModule/locations'];
    const userInfo: AuthorizedUser = rootGetters['AuthModule/userInfo'];

    if (!myCampaigns?.length) {
      const campaigns = await loadMyCampaigns();

      const sortedCampaigns = campaigns.sort(sortCampaignByStartDate);

      const loadedMyCampaigns = sortedCampaigns.map((campaign) =>
        extractMyCampaignData(campaign, locationsList, userInfo)
      );

      const runningCampaigns = sortedCampaigns
        .filter(isCampaignRunningOrUpcoming)
        .map((campaign) =>
          extractMyCampaignData(campaign, locationsList, userInfo)
        );

      const draftCampaigns = sortedCampaigns
        .filter(isCampaignDraft)
        .map((campaign) =>
          extractMyCampaignData(campaign, locationsList, userInfo)
        );

      const closedCampaigns = sortedCampaigns
        .filter(isCampaignClosed)
        .map((campaign) =>
          extractMyCampaignData(campaign, locationsList, userInfo)
        );

      const pendingCampaigns = sortedCampaigns
        .filter(isCampaignPendingAdvertiserAction)
        .map((campaign) =>
          extractMyCampaignData(campaign, locationsList, userInfo)
        );

      commit('setMyCampaigns', {
        myCampaigns: loadedMyCampaigns,
        draftCampaigns,
        closedCampaigns,
        pendingCampaigns,
        runningCampaigns,
      });
    }
  },
  changeCampaignMedia: async (
    _: ActionContext<AdvertiserState, RootState>,
    { campaignRequest, status, newMediaList, oldMediaList }: ChangeMediaData
  ) => {
    const updatedCampaignMediaList: CampaignMedia[] = oldMediaList.map(
      (oldMedia: MediaFile) => {
        const updatedMedia = newMediaList.find(newMedia => newMedia.adSpaceId === oldMedia.adSpaceId)!
        const isMediaChanged = oldMedia.path !== updatedMedia.path;
        const oldCampaignMedia = mapMediaFileToCampaignMedia(oldMedia);
        const newCampaignMedia = mapMediaFileToCampaignMedia(updatedMedia);

        if (!isMediaChanged) {
          return oldCampaignMedia;
        }

        if (isMediaChanged && status.VAL === SYSTEM_STATUS.RUNNING.VAL) {
          return {
            ...oldCampaignMedia,
            MEDIA_CHANGE_REQUEST: {
              ...newCampaignMedia,
              MEDIA_UPLOADED_AT: firebase.firestore.Timestamp.now(),
              STATUS: SYSTEM_STATUS.PENDING_APPROVAL,
            } as CampaignMedia
          } as CampaignMedia;
        } else {
          return {
            ...newCampaignMedia,
            MEDIA_UPLOADED_AT: firebase.firestore.Timestamp.now(),
            MEDIA_CHANGE_REQUEST: null,
          } as CampaignMedia;
        }
      }
    );
    const updatedCampaignPropertiesByStatus = updateCampaignPropertiesByStatusWhenMediaChange(status);

    return changeCampaignMediaList(campaignRequest, updatedCampaignMediaList, updatedCampaignPropertiesByStatus);
  },
  getCampaignById: async (
    { state: { myCampaigns = [] } }: ActionContext<AdvertiserState, RootState>,
    campaignId: string
  ) => {
    return myCampaigns.find(({ campaignRequest: { ID } }) => ID === campaignId);
  },
};
