export default {
  USERS_INFO: 'USERS_INFO',
  REGULATORS: 'REGULATORS',
  BILLING_CONTACTS: 'BILLING_CONTACTS',
  MEDIA_FILES: {
    VAL: 'MEDIA_FILES',
    FOR_CAMPAIGNS: 'FOR_CAMPAIGNS',
  },
  PROMOTIONS: 'PROMOTIONS',
  COMPANY_PROFILES: 'COMPANY_PROFILES',
  CAMPAIGNS: {
    VAL: 'CAMPAIGNS',
    USER_CAMPAIGNS: 'USER_CAMPAIGNS',
    ADMIN_CAMPAIGNS: 'ADMIN_CAMPAIGNS',
    STORE_CAMPAIGNS: 'STORE_CAMPAIGNS',
  },
  functions: {
    CREATE_HOSTING_REQUEST: 'CREATE_HOSTING_REQUEST',
    CREATE_STORE_OWNER_CAMPAIGN: 'CREATE_STORE_OWNER_CAMPAIGN',
    CREATE_STORE_ACCOUNT: 'CREATE_STORE_ACCOUNT',
    GET_UPCOMING_SCHEDULES: 'GET_UPCOMING_SCHEDULES',
    GET_UPLOAD_TOKEN: 'GET_UPLOAD_TOKEN',
    GET_CAMPAIGN: 'GET_CAMPAIGN',
    CAMPAIGN_PAYMENT_INFO: 'CAMPAIGN_PAYMENT_INFO',
    GET_SCREEN_CLUSTER_CONNECTED_DEVICES: 'GET_SCREEN_CLUSTER_CONNECTED_DEVICES',
    CHECKOUT_CAMPAIGN: 'CHECKOUT_CAMPAIGN',
    SHOULD_RECEIVE_INVITATION: 'SHOULD_RECEIVE_INVITATION',
    REDEEM_INVITATION_CODE: 'REDEEM_INVITATION_CODE',
    GET_CLUSTER_SCHEDULES: 'GET_CLUSTER_SCHEDULES',
    DOWNLOAD_INVOICE: 'DOWNLOAD_INVOICE',
    DOWNLOAD_ESTIMATE: 'DOWNLOAD_ESTIMATE',
    REQUEST_ACCESS_TOKEN: 'REQUEST_ACCESS_TOKEN',
    GENERATE_SIGN_IN_LINK: 'GENERATE_SIGN_IN_LINK',
    GET_CLUSTERS_BY_ADSPACES_IDS: 'GET_CLUSTERS_BY_ADSPACES_IDS',
    GET_ADSPACES_BY_CLUSTERS_IDS: 'GET_ADSPACES_BY_CLUSTERS_IDS',
    GET_ADSPACE_PLAYLIST: 'GET_ADSPACE_PLAYLIST',
    PUBLISH_ALL_PLAYLISTS: 'PUBLISH_ALL_PLAYLISTS',
    UPDATE_STORE_OWNER_CAMPAIGN: 'UPDATE_STORE_OWNER_CAMPAIGN',
  },
  AD_SPACES: 'AD_SPACES',
  SHASHA_ADS: {
    VAL: 'SHASHA_ADS',
  },
  SCREEN_CLUSTERS: {
    VAL: 'SCREEN_CLUSTERS'
  },
  remoteConfig: {
    HUNDRED_SAR_FOR_FIRST_WEEK_OFFER: 'HUNDRED_SAR_FOR_FIRST_WEEK_OFFER',
    TWENTY_PERCENT_OFF_FOUR_WEEKS_PROMO: 'TWENTY_PERCENT_OFF_FOUR_WEEKS_PROMO',
    CALENDAR_SCHEDULES_TIME_SLOT_INFO: 'CALENDAR_SCHEDULES_TIME_SLOT_INFO',
    CALENDAR_WEEKS_HORIZONTAL_SELECTION: 'CALENDAR_WEEKS_HORIZONTAL_SELECTION',
    UPCOMING_WEEKS_COUNT: 'UPCOMING_WEEKS_COUNT',
    PAY_LATER_FOR_CAMPAIGN: 'PAY_LATER_FOR_CAMPAIGN',
    PENDING_PAYMENT_TIMEOUT_IN_HOURS: 'PENDING_PAYMENT_TIMEOUT_IN_HOURS',
    CAMPAIGN_SINGLE_CLUSTER_SELECTION: 'CAMPAIGN_SINGLE_CLUSTER_SELECTION',
    ALLOW_SELECTING_RUNNING_SCHEDULE: 'ALLOW_SELECTING_RUNNING_SCHEDULE',
    ACTIVE_STORES_ON_PLATFORM: 'ACTIVE_STORES_ON_PLATFORM',
    SHOW_ONLY_ACTIVE_STORES_ON_PLATFORM: 'SHOW_ONLY_ACTIVE_STORES_ON_PLATFORM',
    MEDIA_FILE_SIZE_LIMIT_IN_MEGA_BYTES: 'MEDIA_FILE_SIZE_LIMIT_IN_MEGA_BYTES',
  },
  STORE_OWNER_CAMPAIGNS: {
    VAL: 'STORE_OWNER_CAMPAIGNS',
  },
};
