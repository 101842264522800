import VueRouter, { RawLocation, Location } from 'vue-router';

export const nextTo =
  (next: (to?: RawLocation) => void) =>
  (routeName: Location) =>
  () => next({
    ...routeName,
  });

export const isRouterReady = (router: VueRouter): Promise<void> => {
  return new Promise((resolve) => {
    router.onReady(() => {
      resolve()
    })
  })
}
