import { SUPPORTED_TYPES } from '@/statics/supported-types';
import moment from 'moment';

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@^+"]+(\.[^<>()\[\]\\.,;:\s@^+"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const testEmailRegex = /^(([^<>()\[\]\\.,;:\s@^"]+(\.[^<>()\[\]\\.,;:\s@^"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
const lowerAndUpperCaseCharsRegex = /^(?=.*[A-Z])(?=.*[a-z])/;
const saudiPhoneRegex = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
const onlyLettersRegex = /^[a-zA-Z]+$/;
const alphanumericRegex = /^[a-zA-Z0-9_]*$/;
export const onlyNumbersRegex = /^[0-9,]+$/;
export const manyWhitespacesRegex = /[ ]{2,}/g;

export const rules = {
  maximumDocumentSizeInMegabytes: (maximumSize: number, msg: string) => (files: File[]) => {
    if (!Array.isArray(files) || !files.length) {
      return true;
    }

    const maximumSizeInBytes = maximumSize * 1048576;

    const nonVideoFiles = files.filter((file) => file.type !== SUPPORTED_TYPES.MP4);
    return nonVideoFiles.every((file) => file.size < maximumSizeInBytes) || msg;
  },
  emailFormat: (msg: string) => (email: string) =>
      email ? (emailRegex.test(email && email.trim()) || msg) : true
  ,
  testEmailFormat: (msg: string) => (email: string) =>
    testEmailRegex.test(email && email.trim()) || msg
  ,
  passwordFormat: (msg: string) => (password: string) =>
    passwordRegex.test(password) || msg
  ,
  shouldHaveEightChars: (msg: string) => (password: string) =>
    password.length >= 8 || msg
  ,
  shouldHaveUpperAndLowerCaseChars: (msg: string) => (password: string) =>
    lowerAndUpperCaseCharsRegex.test(password) || msg
  ,
  saudiPhoneFormat: (msg: string) => (phone: string): boolean | string =>
    saudiPhoneRegex.test(phone && phone.replace(/ /g, '')) || msg
  ,
  required: (msg: string) => (value: string | any[]) => {
    if (Array.isArray(value)) {
      return (value && value.length > 0) || msg;
    }
    return Boolean(value) || msg;
  },
  shouldNotHaveSpaces: (msg: string) => (value: string) =>
    !value.includes(' ') || msg
  ,
  shouldNotBeWhitespaces: (msg: string) => (value: string) =>
    value && (value.trim().length > 0 || msg)
  ,
  shouldNotStartWithWhitespaces: (msg: string) => (value: string) =>
    value && (value.trimStart().length === value.length || msg)
  ,
  shouldNotEndWithWhitespaces: (msg: string) => (value: string) =>
    value && (value.trimEnd().length === value.length || msg)
  ,
  shouldNotHaveManyMiddleWhitespaces: (msg: string) => (value: string) =>
    value && (!manyWhitespacesRegex.test(value) || msg)
  ,
  onlyLetters: (msg: string) => (value: string) =>
    onlyLettersRegex.test(value) || msg // Localization key: 'only_letters_allowed'
  ,
  onlyNumbers: (msg: string) => (value: string) =>
    onlyNumbersRegex.test(value) && !isNaN(Number(value)) || msg // Localization key: 'only_numbers_allowed'
  ,
  onlyPositiveNumber: (msg: string) => (value: string) =>
    onlyNumbersRegex.test(value) && Number(value) > 0 || msg
  ,
  onlyPositiveNumberOptional: (msg: string) => (value: string) =>
    onlyNumbersRegex.test(value) && Number(value) > 0 || !value || msg
  ,
  onlyAlphanumeric: (msg: string) => (value: string) =>
    alphanumericRegex.test(value) || msg // Localization key: 'only_alphanumerics_allowed'
  ,
  minLength: (min: number, msg: string) => (value: string) =>
    value && value.length >= min || msg
  ,
  maxLength: (max: number, msg: string) => (value: string) =>
    value && value.length <= max || msg
  ,
  maxLengthOptional: (max: number, msg: string) => (value: string) =>
    value && value.length <= max || !value || msg
  ,
  minDate: (minDate: string, msg: string) => (date: string) => {
    if (!date) {
      return false;
    }

    const minDateISO = new Date(minDate).toISOString();
    const dateISO = new Date(date).toISOString()
    const minDateMoment = moment(minDateISO);
    const dateMoment = moment(dateISO);
    return dateMoment.isSameOrAfter(minDateMoment) || msg
  }
};

