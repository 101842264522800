
import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component({
  name: 'CampaignTimeLeft',
  components: {},
})
export default class CampaignTimeLeft extends Vue {
  @Prop({ type: Date })
  public startDate!: Date;

  @Prop({ type: Date })
  public endDate!: Date;

  public get percentage() {
    const showPrecise = true;
    const daysPassed = moment(new Date(this.endDate)).diff(moment(new Date()),'days', showPrecise);
    const totalDuration = moment(new Date(this.endDate)).diff(moment(new Date(this.startDate)),'days', showPrecise);
    const timePercentage = ((daysPassed) / totalDuration) * 100;
    return Math.min(timePercentage, 100);
  }
}
